import React, {PureComponent} from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {ICourseMaterial} from '../interfaces/i_courses';
import {HeaderSmall} from '../components/header';
import CourseMaterial from '../containers/course_material';
import {getOffset} from '../utils';
import MaterialType from '../components/material_type/MaterialType';
import {apiGetSingleMaterial} from '../services/apiCourses';
import OgTags from '../components/OgTags';


interface MatchParams {
  page: string;
  stream_id: string;
  id: string;
}

interface State {
  error?: any,
  ready?: boolean,
  preload?: boolean,
  material: ICourseMaterial;
  titleInHeader?: boolean;
}


class CourseMaterialPage extends PureComponent<RouteComponentProps<MatchParams>> {

  state: State = {
    material: {},
    ready: false,
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.match.params?.id !== this.props.match.params?.id) {
      this.init();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenToScroll);
    this.init();

  }

  listenToScroll = () => {
    const heightToHideFrom = getOffset(document.querySelector('#content-wrap'));
    let titleInHeader = Boolean(window.scrollY && (window.scrollY > (heightToHideFrom - 80)));
    if (titleInHeader !== this.state.titleInHeader) {
      this.setState({titleInHeader});
    }
  }

  init = async () => {
    window.scrollTo(0, 0);
    const {location}: any = this.props;
    const {material} = location?.state || {};
    this.setState({material, preload: true});
    await this.handleUpdateMaterial();
  }

  handleUpdateMaterial = async () => {
    const {match}: any = this.props;
    let {stream_id, id} = match.params || {};
    const material = await apiGetSingleMaterial(stream_id, id);
    console.log(material);
    this.setState({material});
    try {
    } catch (e) {
    } finally {
      this.setState({preload: false, ready: true});
    }
  }

  handleBack = () => {
    this.props.history.replace(`/courses/${this.state.material?.stream_id}`, {fromMaterial: this.state.material});
  }

  handleNextClick = (material: any) => {
    this.props.history.replace(`/material/${material.stream_id}/${material.id}`, {material});
  }

  render() {
    const {titleInHeader, material} = this.state;
    return (
      <>
        <OgTags data={this.state.material} />
        <HeaderSmall
          handleGoFromNotif={this.handleUpdateMaterial}
          onHeaderBack={this.handleBack}
          showCenter={titleInHeader}
          renderCenter={() => (
            <div className="header-center">
              <MaterialType material={material} />
              <div className="bold-14 d-grid overflow-hidden">
                <span className=" text-truncate">{material?.name}</span>
              </div>
            </div>
          )}
        />
        <CourseMaterial
          {...this.state}
          handleNextClick={this.handleNextClick}
          handleUpdateMaterial={this.handleUpdateMaterial}
        />
      </>
    );
  }
}

export default withRouter(CourseMaterialPage);
