import {action, makeAutoObservable, observable, toJS} from 'mobx';
import {RootStore} from './RootStore';
import {apiGetUser, apiLogin, apiLogout, apiUpdateUser, apiUpdatePassByHash} from '../services/apiUser';
import {IUser} from '../interfaces/i_user';

export class UserStore {
  rootStore: any;
  user: IUser = {};

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {
      user: observable,

      logIn: action,
      logOut: action,
      loginByHash: action,
      getUser: action,
      updateUser: action,
      setState: action,
    });
  }

  getUser = async (): Promise<IUser> => {
    try {
      const user = await apiGetUser();
      this.user = user;
      return user;
    } catch (e) {
      throw e;
    }
  };

  updateUser = async (data: any): Promise<IUser> => {
    try {
      let user = await apiUpdateUser(data);
      this.user = user;
      return user;
    } catch (e) {
      throw e;
    }
  };
  updateUserObj = async (data: IUser): Promise<void> => {
    try {
      this.user = {...this.user, ...data};
    } catch (e) {
      throw e;
    }
  };
  logOut = async () => {
    try {
      await apiLogout();
      this.rootStore.appStore.isAuth = false;
      this.user = {};
    } catch (e: any) {
    }
  };

  logIn = async (data: any) => {
    try {
      this.user = await apiLogin(data);
      this.rootStore.appStore.isAuth = true;
    } catch (e: any) {
      throw e;
    }
  };
  loginByHash = async (req: any) => {
    try {
      this.user = await apiUpdatePassByHash(req);
    } catch (e: any) {
      // toast(e ? e?.message || '' : 'Invalid Server Response');
      throw e;
    }
  };
  setState = (object: object): void => {
    for (let key in object) {
      //@ts-ignore
      this[key] = toJS(object[key]);
    }
  };
}
