import React, {FC, memo, useCallback} from 'react';
import './notifications.css'
import {useHistory} from 'react-router-dom';
import {INotification} from '../../../interfaces/i_notifications';
import NotificationListItem from './NotificationListItem';
import {IPagination} from '../../../interfaces/i_app';
import EmptyList from '../../../components/empty_list';
import NotificationsFooterControls from './NotificationsFooterControls';
import NotificationsShowMore from './NotificationsShowMore';

interface Props {
  notifications: INotification[];
  meta?: IPagination;
  get: (page?: number) => void;
  close: () => void;
  readAllNotif: () => void;
  loading: boolean;
  notifBadge: number;
  removeNotif: (notif?: number | 'removeAll') => void;
  handleGoFromNotif?: (material?: any) => void;
}

const NotificationList: FC<Props> = ({
                                       notifications,
                                       notifBadge,
                                       removeNotif,
                                       close,
                                       handleGoFromNotif,
                                       loading,
                                       meta,
                                       get,
                                       readAllNotif
                                     }) => {
  const history: any = useHistory();

  const handleClick = useCallback((notification: INotification) => {
    const isSmallDevice = window.innerWidth <= 768;
    if (notification.type === 'task') {
      const material = {
        id: notification.entity_id,
        name: notification.data.material_name,
        type: notification.type,
        stream_id: notification.data?.stream_id,
      }
      if (isSmallDevice) {
        history.push(`/task-chat/${material.stream_id}/${material.id}`, {material});
        if (handleGoFromNotif) {
         setTimeout(() => handleGoFromNotif(material),0);
        }
      } else  {
        history.push(`/courses/${notification.data?.stream_id}`, {material});
        if (handleGoFromNotif) {
          handleGoFromNotif(material);
        }
      }
    }
    else if(notification.type === 'stream_start') {
      history.push(`/courses/${notification.data.stream_id}`);
    }
    close();
  }, []);

  const showMore = () => {
    if (loading) return null;
    const page = (meta?.current_page || 1) + 1;
    get(page);
  }

  return (
    <>
      <div className="notifications-list">
        {notifications?.length
          ?
          notifications.map(notification => <NotificationListItem
            notification={notification}
            key={notification.id}
            handleClick={handleClick}
            handleRemove={removeNotif}
          />)
          :
          loading
            ?
            null
            :
            <EmptyList title="NOTIFICATION_LIST_EMPTY"/>
        }
      </div>
      <div className="notification-footer">
      <NotificationsFooterControls
        handleReadNotif={readAllNotif}
        handleRemoveAllNotif={removeNotif}
        loading={loading}
        notifications={notifications}
        badge={notifBadge}
      />
      <NotificationsShowMore onClick={showMore} meta={meta} />
      </div>
    </>
  );
}


export default memo(NotificationList);
