import React, {FC} from 'react';
import {translate} from '../../utils';
import {observer} from 'mobx-react';
import {ICourseProgress} from '../../interfaces/i_courses';

interface Props {
  progress?: ICourseProgress;
  className?: string
}

const ProgressTitle: FC<Props> = ({ progress, className}) => {
  return (
    <div className={`progress-title ${className}`}>
      {progress?.count_ready ? <div className="text-12">{translate('Learned')} {progress.count_ready}</div> : null}
      <div className="muted-12">{translate('Available')} {progress?.count_available || 0}/{progress?.count_all || 0}</div>
    </div>

  );
}


export default observer(ProgressTitle);
