import React, {FC, useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import './date_info.css'
import {getLocalDate, getSecondsUntilDate, translate} from '../../utils';
import start_svg from '../../assets/icons/start.svg';
import started_svg from '../../assets/icons/started.svg';
import RenderSvg from '../RenderSvg';

interface Props {
  date?: string;
  title?: string;
  className?: string;
  start?: boolean;
}

const DateInfo: FC<Props> = ({date, start, title, className}) => {
  const [ready, setReady] = useState(false);
  const [started, setStarted] = useState(false);

  useEffect(() => {
    let timer: any;
    if (date && start) {
      let timestamp = getSecondsUntilDate(date || '');
      if (timestamp > 0) {
        setStarted(false)
        timer = setTimeout(() => {
          setStarted(true);
        }, timestamp * 1000);
      } else {
        setStarted(true);
      }
    }
    setReady(true)
    return () => clearTimeout(timer);
  }, [date]);

  if (!date || !ready) return null;
  return (
    <div className={`date-info ${className || ''}`}>
      <div className="date-info-title">
        {start
          ?
          <span className="date-info-icon">
            <RenderSvg path={started ? started_svg : start_svg} size={started ? 28 : 20}/>
          </span>
          :
          null
        }
        <span>{translate(title || (start ? (started ? 'COURSE_STARTED' : 'start_date') : 'DATE_OPEN_MATERIAL'))}</span>
      </div>
      {date
        ?
        <>
          <div>{getLocalDate(date, 'DD.MM.YYYY')}</div>
          <div className="muted">{getLocalDate(date, 'HH:mm')}</div>
        </>
        :
        null
      }
    </div>
  );
}


export default observer(DateInfo);
