import React, {FC, useEffect, useState} from 'react';
import {Modal} from 'react-responsive-modal';
import Lightbox from 'react-image-lightbox';

interface Props {
  visible: string;
  handleClose: () => void;
  images: any[];
}


const ImagesModal: FC<Props> = ({visible, handleClose, images}) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  useEffect(() => {
    if (visible) {
      setPhotoIndex(Number(visible) || 0);
    } else {
      setPhotoIndex(0);
    }
  }, [visible])
  return (
    <Modal
      open={Boolean(visible)}
      onClose={handleClose}
      center
      showCloseIcon={false}
      //@ts-ignore
      classNames={{
        modal: 'gallery-modal',
      }}
    >
      {visible
        ?
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={handleClose}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
        />
        :
        null
      }
    </Modal>
  );
}

export default ImagesModal;
