import React, {PureComponent} from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {ICourseMaterial} from '../interfaces/i_courses';
import {HeaderSmall} from '../components/header';
import MaterialType from '../components/material_type/MaterialType';
import {apiGetSingleMaterial, apiGetTaskChat} from '../services/apiCourses';
import {TaskAnswers} from '../components/task';
import {ITaskAnswer} from '../interfaces/i_tasks';
import {scrollToBottomChat} from '../containers/course_article/ContentCourseTask';
import ErrorData from '../components/ErrorData';
import Spinner from '../components/spinner';
import OgTags from '../components/OgTags';

interface MatchParams {
  stream_id: string;
  id: string;
}

interface State {
  error?: any,
  loading?: boolean,
  preload?: boolean,
  task: ICourseMaterial;
  answers?: ITaskAnswer[];
}

class TaskChatPage extends PureComponent<RouteComponentProps<MatchParams>> {
  _interval: any;

  state: State = {
    task: {},
    loading: true,
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.match.params?.id !== this.props.match.params?.id) {
      this.init();
    }
  }

  componentWillUnmount() {
    clearInterval(this._interval);
  }

  componentDidMount() {
    this.init();
    this._interval = setInterval(this.init, 30000);
  }


  init = async () => {
    this.setState({preload: true});
    try {
      await this.getTaskInfo();
      await this.getTaskAnswers();
    } catch (e) {
      this.setState({error: e});
    } finally {
      this.setState({loading: false});
    }
  }

  getTaskInfo = async () => {
    const {match}: any = this.props;
    let {stream_id, id} = match.params || {};
    const task = await apiGetSingleMaterial(stream_id, id);
    this.setState({task});
    try {
    } catch (e) {
      throw e;
    } finally {

    }
  }

  getTaskAnswers = async () => {
    const id = this.props.match.params.id;
    try {
      this.setState({preload: true});
      const answers = await apiGetTaskChat(id);
      this.setState({answers, preload: false});
      // window.scrollTo({top: 999999, behavior: 'auto'});
      scrollToBottomChat(true);
    } catch (e) {
      throw e;
    }
  }

  handleBack = () => {
    if(this.state.error) {
      this.props.history.replace(`/courses`);
    } else {
      const {task} = this.state;
      this.props.history.replace(`/material/${task.stream_id}/${task.id}`);
    }

  }

  handleNextClick = (material: any) => {
    this.props.history.replace(`/material/${material.stream_id}/${material.id}`, {material});
  }

  render() {
    const {task, error, loading} = this.state;
    return (
      <div  style={{height: '100vh'}}>
        <OgTags data={this.state.task} />
        <HeaderSmall
          handleGoFromNotif={this.init}
          onHeaderBack={this.handleBack}
          showCenter={true}
          renderCenter={() => (
            <div className="header-center">
              <MaterialType material={task} />
              <div className="bold-14 d-grid overflow-hidden">
                <span className=" text-truncate">{task.name}</span>
              </div>
            </div>
          )}
        />
        {error
          ?
          <div className="task-content d-flex align-items-center justify-content-center"><ErrorData /></div>
          :
          loading
            ? <Spinner style={{top: 90}} />
            : <TaskAnswers {...this.state} updateList={this.init} />
        }
      </div>
    );
  }
}

export default withRouter(TaskChatPage);
