import React, {FC} from 'react';
import {ICourseMaterial} from '../../../interfaces/i_courses';
import SiderActionItemWrap from '../course_sider/SiderActionItemWrap';
import TextTruncate from 'react-text-truncate';
import MaterialType from '../../../components/material_type';

interface Props {
  data: ICourseMaterial;
  handleClick: (data: any) => void;
  active?: boolean;
}

const SiderLessonTask: FC<Props> = ({data, active, handleClick}) => {
  const disabled = !data?.available;
  const onClick = () => {
    if (disabled) return null;
    handleClick({type: data.type, ...data});
  }
  return (
    <SiderActionItemWrap active={active} onClick={onClick} className={`sider-lesson${disabled ? ' disabled' : ''}`}>
      <div className="d-flex align-items-center" data-id={data.id}>
        <div className="sider-numeric">{data.number || 'N/A'}</div>
        <div>
          <MaterialType material={data} />
          <div dir={'auto'}>
            <TextTruncate
              line={2}
              element="div"
              containerClassName="sider-name"
              truncateText="…"
              text={data.name}
            />
          </div>
        </div>
      </div>
    </SiderActionItemWrap>
  );
}

export default SiderLessonTask;
