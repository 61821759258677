import React, {FC} from 'react';
import SVG from 'react-inlinesvg';


interface Props {
  type?: 'regular' | 'muted';
  path: any;
  size?: number;
  rotate?: number;
}

const RenderSvg: FC<Props> = ({type, size = 16, path, rotate = 0}) => {
  return (
    <SVG className={`svg_${type || 'regular'}`} src={path} style={{width: size, height: size, transform: `rotate(${rotate}deg)`}} />
  );
}

export default RenderSvg;
