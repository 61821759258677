import React, {FC} from 'react';
import {ITaskAnswer} from '../../../interfaces/i_tasks';
import TaskAnswerItem from './taskAnswerItem';
import {ITask} from '../../../interfaces/i_tasks';
import TaskStatus from '../TaskStatus';
import TaskAnswerFooter from './TaskAnswerFooter';
import EmptyAnswersList from './EmptyAnswersList';
import {translate} from '../../../utils';
import {observer} from 'mobx-react';

interface Props {
  animated?: boolean;
  task?: ITask;
  answers?: ITaskAnswer[];
  updateList: () => void;
}

const TaskAnswers: FC<Props> = ({animated, task, answers, updateList}) => {
  return (
    <div className={`task-answers${animated ? ' animate__fadeInDown fadeInDown animate__animated' : ''}`}>
      <div className="muted-14 pb-2">{translate('Answer')}</div>
      <div className="task-content">
        {answers?.length
            ? answers.map(answer => (
              <TaskAnswerItem answer={answer} key={answer.id} />
            ))
            : <EmptyAnswersList />
        }
        <TaskStatus status={task?.status} className="pb-4 mb-4" />
      </div>
      <TaskAnswerFooter taskId={task?.id} updateList={updateList} />
    </div>
  );
}


export default observer(TaskAnswers);
