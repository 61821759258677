import React, {FC} from 'react';
import ProgressTitle from './ProgressTitle';
import {ICourseProgress} from '../../interfaces/i_courses';

const getPercent = (full = 0, progress = 0): string => {
  const percent = progress * 100 / full;
  return percent + '%';
}

interface Props {
  progress: ICourseProgress;
  className?: string;
}

const Progress: FC<Props> = ({progress, className}) => {
  return (
    <div className="w-100">
      <ProgressTitle progress={progress} className="pb-2" />
      <div className={`progress ${className || ''}`}>
        <div className="available-progress" style={{width: getPercent(progress.count_all, progress.count_available)}} />
        <div className="active-progress" style={{width: getPercent(progress.count_all, progress.count_ready)}} />
      </div>
    </div>
  );
}

export default Progress;
