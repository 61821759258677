import React, {FC} from 'react';
import {ICourseMaterial} from '../../../interfaces/i_courses';
import TextTruncate from 'react-text-truncate';

interface Props {
  data: ICourseMaterial;
}

const SiderSection: FC<Props> = ({data}) => {

  return (
    <div className={`sider-item${!data?.available ? ' disabled' : ''}`}>
      <TextTruncate
        line={2}
        element="h5"
        containerClassName="bold"
        truncateText="…"
        text={data.name}
      />
    </div>
  );
}

export default SiderSection;
