import React, {FC, useCallback, useEffect, useState} from 'react';
import './course_article_small.css';
import {ICourseArticleComponentProps} from '../../interfaces/i_courses';
import {useHistory} from 'react-router-dom';
import Spinner from '../../components/spinner';
import CourseStarted from './CourseStarted';
import CourseNotStarted from './CourseNotStarted';
import {HeaderSmall} from '../../components/header';
import ProgressTitle from '../../components/progress/ProgressTitle';
import {getOffset} from '../../utils';

interface Props extends ICourseArticleComponentProps {

}

const CourseArticleSmall: FC<Props> = (props) => {
  const history: any = useHistory();
  const [secondTypeVisible, setSecondTypeVisible] = useState(false);
  const [isVisibleHeaderCenter, setIsVisibleHeaderCenter] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    return () =>
      window.removeEventListener('scroll', listenToScroll);
  }, []);

  const listenToScroll = () => {
    const heightToHideFrom = getOffset(document.querySelector('#sider-materials'));
    let titleInHeader = Boolean(window.scrollY && window.scrollY > (heightToHideFrom / 3));
    setIsVisibleHeaderCenter(titleInHeader);
  };

  const handleChangeTypeClick = () => {
    setSecondTypeVisible(true);
  }

  const handleBack = () => {
    if (secondTypeVisible) {
      setSecondTypeVisible(false);
    } else {
      history.replace(`/courses`);
    }
  };

  const handleSiderClick = useCallback((material: any) => {
    if (!material.available) return null;
    history.push(`/material/${material.stream_id}/${material.id}`, {material});
  }, []);


  return (
    <>
      <HeaderSmall
        close={secondTypeVisible}
        onHeaderBack={handleBack}
        showCenter={(!props.courseStarted && secondTypeVisible) || (props.courseStarted && isVisibleHeaderCenter)}
        renderCenter={() => (
          <div className="header-center align-self-end">
            <div className="d-grid overflow-hidden">
              <span className="bold-14 text-truncate" dir={'auto'}>{props.parent.name}</span>
            </div>
            <ProgressTitle progress={props.progress} />
          </div>
        )}
      />
      {!props.ready
        ?
        <Spinner style={{top: 106}} />
        :
        props.courseStarted
          ? <CourseStarted
            {...props}
            secondTypeVisible={secondTypeVisible}
            handleChangeTypeClick={handleChangeTypeClick}
            handleSiderClick={handleSiderClick}
          />
          : <CourseNotStarted
            {...props}
            secondTypeVisible={secondTypeVisible}
            handleChangeTypeClick={handleChangeTypeClick}
            handleSiderClick={handleSiderClick}
          />
      }
    </>
  );
}

export default CourseArticleSmall;
