import React, {FC} from 'react';
import {ButtonHTMLAttributes} from 'react';
import arrow from '../../assets/icons/arrow-right.svg';
import Spinner from '../spinner';
import {observer} from 'mobx-react';
import {translate} from '../../utils';
import SVG from 'react-inlinesvg';

interface Props extends ButtonHTMLAttributes<any> {
  type?: 'submit' | 'button';
  className?: string;
  loading?: boolean;
}

const GoToBtn: FC<Props> = ({
                              type,
                              loading,
                              className,
                              ...props
                            }) => {
  return (
    <button
      className={`btn btn-primary btn-go-to${loading ? ' loading' : ''}${props.disabled ? ' disabled' : ''} ${className || ''}`}
      type={type || 'button'} {...props}>
      {loading
        ? <Spinner className="position-relative" small white />
        :
        <>
          <span className="flex-grow-1">{translate('Go_to')}</span>
          <SVG src={arrow} />
        </>
      }
    </button>
  );
}


export default observer(GoToBtn);
