import React, {FC} from 'react';
import face from '../../assets/images/applications/face.png';
import email from '../../assets/images/applications/email.png';
import lock from '../../assets/images/applications/lock.png';

const iconsMap: any = {
  login: face,
  recovery: lock,
  success_email: email,
}

interface Props {
  screen: string;
  className?: string;
}

const LoginIcon: FC<Props> = ({screen,className}) => {
  return (
    <div className={`logo-wrap ${className || ''}`}>
      <img src={iconsMap[screen]} alt="img" />
      {/*<SVG src={iconsMap[screen]} />*/}
    </div>
  );
}

export default LoginIcon;
