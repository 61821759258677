import React, {FC, memo} from 'react';
import {TTaskStatus} from '../../interfaces/i_tasks';
import rework from '../../assets/icons/refresh.svg';
import accept from '../../assets/icons/check.svg';
import pending from '../../assets/icons/time.svg';
import RenderSvg from '../RenderSvg';
import {observer} from 'mobx-react';
import {translate} from '../../utils';

const statusIcon: any = {
  rework,
  accept,
  pending,
  wait: pending,
}

interface Props {
  status?: TTaskStatus;
  small?: boolean;
  mediumStyle?: boolean;
  className?: string;
}

const TaskStatus: FC<Props> = ({status, small, mediumStyle, className}) => {
  if (!status) return null;
  return (
    <div
      className={`task-status ${status || ''}${small ? ' small' : ''}${mediumStyle ? ' medium' : ''} ${className || ''}`}>
      <div className="task-status-content">
        {status ?
          <div className="task-status-icon"><RenderSvg path={statusIcon[status]} size={small ? 14 : 20} /></div> : null}
        {small ? null : <div className="task-status-name">{translate(`task_status_${status}`)}</div>}
      </div>
    </div>
  );
}


export default memo(observer(TaskStatus));
