import React, {FC} from 'react';
import {observer} from 'mobx-react';
import {translate} from '../../../utils';
import SVG from 'react-inlinesvg';
import brain from '../../../assets/icons/applications/brain.svg'
import arrow from '../../../assets/icons/applications/arrow.svg'

interface Props {
}

const EmptyAnswersList: FC<Props> = () => {

  return (
    <div className="task-answer-empty">
      <SVG src={brain} />
      <div className="muted pt-4 position-relative">
        <span>{translate('SEND_RESPONSE_TO_CURATOR')}</span>
        <div className="task-answer-empty-arrow"><SVG src={arrow} /></div>
      </div>

    </div>
  );
}


export default observer(EmptyAnswersList);
