import React, {FC} from 'react';
import CourseArticleSmallHeader from './CourseArticleSmallHeader';
import {ICourseArticleComponentProps} from '../../interfaces/i_courses';
import SiderMaterials from '../course_article/course_sider/SiderMaterials';
import ContentCourseAbout from '../course_article/ContentCourseAbout';

interface Props extends ICourseArticleComponentProps {
  secondTypeVisible: boolean;
  handleChangeTypeClick: () => void;
  handleSiderClick: (material: any) => void;
}

const CourseStarted: FC<Props> = ({handleSiderClick, secondTypeVisible,handleChangeTypeClick, ...props}) => {


  return (
      <main className="course-article__small">
        {!secondTypeVisible
          ?
          <>
            <CourseArticleSmallHeader handleDetails={handleChangeTypeClick} course={props.parent} />
            <SiderMaterials {...props} setSelected={handleSiderClick} />
          </>
          :
          <ContentCourseAbout className='pt-3' data={props.parent} getMaterialsList={() => {
          }} />
        }
      </main>
  );
}

export default CourseStarted;
