import React, {FC} from 'react';
import './logo.css';
import {isLogo, LOGO, LOGO_SMALL} from "../../utils/api";

interface Props {
  className?: string;
  small?: boolean;
  handleClick?: () => void;
}

const Logo: FC<Props> = ({small, className, handleClick}) => {
  return (
    <div className={`logo ${className || ''}`} onClick={handleClick}>
      {isLogo ? <img src={small ? LOGO_SMALL : LOGO} alt="Logo"/> : null}
    </div>
  );
}

export default Logo;
