import React, {FC} from 'react';
import {translate} from '../../../utils';
import RenderSvg from '../../RenderSvg';
import trash from '../../../assets/icons/trash.svg';
import {observer} from 'mobx-react';
import {INotification} from '../../../interfaces/i_notifications';

interface Props {
  loading: boolean;
  badge: number;
  notifications?: INotification[];
  handleReadNotif: () => void;
  handleRemoveAllNotif: (notif: 'removeAll') => void;
}

const NotificationsFooterControls:FC<Props> = ({notifications, badge, loading, handleRemoveAllNotif, handleReadNotif}) => {
  return (
    <div className="notifications-footer-controls">
      {badge && !loading
        ?
        <div className="notification-mark-read-as" onClick={handleReadNotif}>
          {translate('MARK_AS_READ')}
        </div>
        :
        <div />
      }
      {notifications?.length
        ?
        <div className="btn btn-outline notifications-clear" onClick={() => handleRemoveAllNotif('removeAll')}>
          <RenderSvg path={trash} />
          <span className="ps-2">{translate('remove_all')}</span>
        </div>
        : null
      }
    </div>
  );
}

export default observer(NotificationsFooterControls);
