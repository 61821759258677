import RootStore from '../store/RootStore';
import langs from '../jsons/langs.json';
import {IUpload} from '../interfaces/i_upload';
import {HOST} from './api';
import moment from 'moment';
import {toast as toastify} from 'react-toastify';
import Toast from '../components/toast';
import {useLocation} from 'react-router-dom';

const toastConfig: any = {
  position: 'top-right',
  autoClose: 4000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  progress: undefined,
}

export function toast(text: string | number, type = 'error') {
  if (!text) return null;
  toastify(Toast, {...toastConfig, data: {type, text}});
}

export function translate(word?: string, key?: 'change', data?: any, returnEmpty?: boolean) {
  if (!word) return '';
  const {translations} = RootStore.appStore;
  const check_word = String(word).toUpperCase();
  if (!translations?.[check_word]) return (returnEmpty ? '' : word);
  let str = translations[check_word] || word;
  if (key) {
    if (key === 'change') {
      str = translations[check_word].replace('$change$', data);
    }
  }
  return `${str}`;
}

export function scrollToEl(el: any, smooth?: boolean): void {
  el?.scrollIntoView({
    behavior: smooth ? 'smooth' : 'auto',
    block: 'center',
    inline: 'nearest',
  });
}

export function getLangTitle(ln?: string): string {
  if (!ln) return '';
  //@ts-ignore
  return langs[ln]?.native || ln;
}

export function addHostToPath(file?: IUpload): string {
  if (!file) return '';
  if (file.url) return `${HOST}${file.url}`;
  return '';
}


export function pageScrollTop(selector?: string): void {
  const el: any = document.querySelector(selector || '.page-list');
  el?.scroll({top: 0, behavior: 'smooth'});
}

export function getLocalDate(date: string, format?: string): string {
  const valid = moment(date).isValid();
  if (!valid) return '';
  return moment.utc(date).local().format(format || 'DD.MM.YYYY');
}

export function getSecondsUntilDate(date?: string): number {
  return date ? moment.utc(date).local().diff(moment.utc().local(), 'seconds') : 0;
}

export function getBgGradientClassName(index = 0): string {
  return 'bg-gradient-' + index % 5;
}

export const getOffset = (element: any) => {
  const offsetTop = element?.getBoundingClientRect()?.top || 0,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return offsetTop + scrollTop;
};

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function setRtlLang(ln: string) {
  const html = document.getElementsByTagName('html')[0];
  if (ln === 'ar') {
    html.dir = 'rtl';
  } else {
    html.dir = 'ltr';
  }
  html.lang = ln;
}
