import React, {FC} from 'react';
import star1 from '../assets/icons/applications/Star1.svg';
import star2 from '../assets/icons/applications/Star2.svg';
import star3 from '../assets/icons/applications/Star3.svg';
import star4 from '../assets/icons/applications/Star4.svg';
import star5 from '../assets/icons/applications/Star5.svg';
import SVG from 'react-inlinesvg';

const starsMap = [star1, star2, star3, star4, star5];

interface Props {
  index: number;
  className?: string;
  animated?: boolean;
}

const BigStar: FC<Props> = ({index, className, animated }) => {
  return (
    <div className={`big-star${animated ? ' animated' : ''} ${className || ''}`}>
      <SVG src={starsMap[index % starsMap.length]} />
    </div>
  );
}

export default BigStar;
