import React, {FC} from 'react';
import {Helmet} from 'react-helmet';
import {addHostToPath} from '../utils';
import {DOC_TITLE} from "../utils/api";

interface Props {
  data?: any;
}

const OgTags: FC<Props> = ({data}) => {
  return (
      <Helmet>
        <meta charSet="utf-8" />
        <title>{DOC_TITLE}{data?.name ? ` - ${data?.name}` : ''}</title>
        <link rel="canonical" href={window.location.origin} />
        <meta name="description" content={data?.description || 'Web courses'} />
        <meta property="og:title" content={data?.name || DOC_TITLE} />
        {data?.theme_settings?.image ?
          <meta property="og:image" content={addHostToPath(data.theme_settings.image)} /> : null}
      </Helmet>
  );
}

export default OgTags;
