import React from 'react';
import './group_chat_modal.css';
import CloseBtn from '../../../components/close_btn';
import {Modal} from 'react-responsive-modal';
import chat from '../../../assets/images/applications/chat.png';
import star from '../../../assets/images/star.png';
import {FC} from 'react';
import {PrimaryBtn} from '../../../components/buttons';
import {translate} from '../../../utils';
import {observer} from 'mobx-react';
import {apiPostData} from '../../../services/apiData';

interface Props {
  visible: boolean;
  onClose: () => void;
  link?: string;
  stream_id?: number;
}

const GroupChatModal: FC<Props> = ({visible, onClose, link, stream_id}) => {

  const handleClick = async () => {
    onClose();
    await apiPostData(`/stream/${stream_id}/setModalShow`, {});
  }

  return (
    <Modal
      open={Boolean(visible)}
      onClose={onClose}
      showCloseIcon={false}
      classNames={{modal: 'group-chat-modal-container', root: 'group-chat-modal-root'}}
      center
    >
      <div className="group-chat-modal">
        <CloseBtn onClick={onClose} />
        <div className={`group-chat-modal-icon`}>
          <div className="group-chat-modal-star"><img src={star} alt="star" /></div>
          <img src={chat} alt="chat" />
        </div>
        <div className="group-chat-modal-head">
          <div className="title-24">{translate('GROUP_CHAT')}</div>
          <div className="text-14">{translate('GROUP_CHAT_MESSAGE')}</div>
        </div>
        <a href={link} className="btn btn-primary" onClick={handleClick} target="_blank">{translate('GO_TO')}</a>
      </div>
    </Modal>
  );
};


export default observer(GroupChatModal);
