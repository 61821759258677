import React, {FC} from 'react';
import Avatar from '../avatar';
import RootStore from '../../store/RootStore';
import {observer} from 'mobx-react';

interface Props {
  handleShowHidden: (type: 'profile') => void;
}

const HeaderProfile: FC<Props> = ({handleShowHidden}) => {
  const {user} = RootStore.userStore || {};
  const handleClick = (e: any) => {
    e.stopPropagation();
    handleShowHidden('profile')
  }
  return (
      <Avatar user={user} className="header-profile" onClick={handleClick} />
  );
}


export default observer(HeaderProfile);
