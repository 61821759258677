import React, {FC} from 'react';
import {ICourseMaterial} from '../../interfaces/i_courses';
import {observer} from 'mobx-react';
import ContentBody from '../../components/content_body';
import ContentPassedBtns from './ContentPassedBtns';
import AdditionalMaterial from './additional_material';
import {apiLearnMaterial} from '../../services/apiCourses';
import CourseMaterialWrapper from './CourseMaterialWrapper';
import NextMaterial from './NextMaterial';
import {toast} from '../../utils';

interface Props {
  loading?: boolean;
  data: ICourseMaterial;
  updateData?: () => void;
  setSelected: (material: any) => void;
}

const ContentCourseLesson: FC<Props> = ({loading, data, updateData, setSelected}) => {
  const handleLearn = async (unread?: number) => {
    try {
      await apiLearnMaterial(data.stream_id, data?.id, unread);
      if (updateData) await updateData();
      if (unread === 0 && data?.next?.available) {
        setSelected(data?.next);
      }
    } catch (e: any) {
      toast(e.message);
    }
  }

  const handleViewExtraMaterial = async (uuid?: string, unread?: number) => {
    try {
      await apiLearnMaterial(data.stream_id, uuid, unread);
      if (updateData) await updateData();
    } catch (e: any) {
      toast(e.message);
    }
  }
  return (
    <>
      <CourseMaterialWrapper
        loading={loading}
        number={data?.number}
        material={data}
        nextComponent={() => <NextMaterial number={data?.number}
                                           material={data?.next}
                                           updateData={updateData}
                                           onClick={setSelected} />}
        leftContent={() => <>
          <ContentBody data={data?.data} />
          <ContentPassedBtns passed={data?.ready} onClick={handleLearn} />
        </>}
        hiddenRight={!data?.extra_content?.length}
        rightContent={() => <AdditionalMaterial materials={data?.extra_content}
                                                handleViewExtraMaterial={handleViewExtraMaterial} />
        }
      />
    </>
  );
}


export default observer(ContentCourseLesson);
