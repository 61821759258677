import React, {FC} from 'react';
import error from '../../assets/icons/error.svg';
import success from '../../assets/icons/check.svg';
import {translate} from '../../utils';
import './toast.css';
import RenderSvg from '../RenderSvg';

interface Props {
  data: { type: string, text: string }
}

const iconsMap: any = {
  error,
  success,
}

const Toast: FC<Props> = ({data}) => {
  return (
    <div className={`my-toast-container my-toast-${data.type}`}>
      <div className="my-toast-icon">
        <RenderSvg path={iconsMap[data.type]} size={32} />
      </div>
      <span className="ps-3 text-14">{translate(data?.text)}</span>
    </div>
  );
}

export default Toast;
