import React, {FC} from 'react';
import {TNotificationType} from '../../../interfaces/i_notifications';
import RenderSvg from '../../RenderSvg';
import chat_curator from '../../../assets/icons/chat_curator.svg';
import BigStar from '../../BigStar';
import {INotification} from '../../../interfaces/i_notifications';

interface Props {
  notification: INotification;
}

const NotificationIcon: FC<Props> = ({notification}) => {
  if (notification.type === 'task') {
    return (
      <div className="notifications-icon">
        <RenderSvg path={chat_curator} size={32} />
      </div>
    );
  }
  if (notification.type === 'stream_start') {
    return (
      <div className="notifications-icon">
        <BigStar index={notification.data?.stream_id || 0} animated />
      </div>
    );
  }
  return null;
}

export default NotificationIcon;
