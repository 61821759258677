import React, {FC} from 'react';
import './additional_material.css';
import {IMaterialData} from "../../../interfaces/i_courses";
import {translate} from "../../../utils";
import {observer} from "mobx-react";
import AdditionalMaterialItem from "./AdditionalMaterialItem";

interface Props {
  materials?: IMaterialData[];
  handleViewExtraMaterial: (uuid?: string, unread?: number) => Promise<void>;
}

const AdditionalMaterial: FC<Props> = ({materials, handleViewExtraMaterial}) => {
  if (!materials?.length) return null;
  return (
    <div className='extra-materials'>
      <h5 className='muted-14 mb-2'>{translate('ADDITIONAL_MATERIAL')}</h5>
      {materials.map((material, i) => (
        <AdditionalMaterialItem key={i}  material={material} handleViewExtraMaterial={handleViewExtraMaterial} />
      ))}
    </div>
  );
}


export default observer(AdditionalMaterial);
