import React, {FC} from 'react';
import {translate} from '../utils';
import {observer} from 'mobx-react';
import SVG from 'react-inlinesvg';

interface Props {
  title: string;
  icon?: any;
}

const PageTitle: FC<Props> = ({title, icon}) => {
  return (
    <div className="page-title-wrap">
      <h4 className="page-title">{translate(title)}</h4>
      {icon ? <div className="ps-2"><SVG src={icon} /></div> : null}
    </div>
  );
}


export default observer(PageTitle);
