import React, {FC} from 'react';

interface Props {
  header?: boolean;
  active?: boolean;
  className?: string;
  onClick: () => void;
}

const SiderActionItemWrap: FC<Props> = ({header, active, onClick, className, children}) => {
  return (
    <div className={`sider-item${header ? ' sider-header' : ''}${active ? ' active' : ''} ${className || ''}`} onClick={onClick}>
      {active
        ?
        <div className="sider-item-arrow">
          <svg width="48" height="168" viewBox="0 0 48 168" fill="none">
            <path
              d="M0 0H2.08674C12.2295 0 21.2772 6.3761 24.6885 15.9279L46.1171 75.9279C47.9814 81.1479 47.9814 86.8521 46.1171 92.0721L24.6885 152.072C21.2772 161.624 12.2295 168 2.08674 168H0V0Z"
              fill="#F6F9FA" />
          </svg>
        </div>
        : null
      }
      {children || null}
    </div>
  );
}

export default SiderActionItemWrap;
