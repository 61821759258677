import React, {FC, useState} from 'react';
import {Modal} from "react-responsive-modal";
import {translate} from "../../../utils";
import {ICourse, ICoursePaymentInfo} from "../../../interfaces/i_courses";
import {observer} from "mobx-react";
import {CloseBtn} from "../../../components/buttons";
import {EPaymentTypes, IOrder} from "../../../interfaces/i_app";
import HyperpayStreamInfo from "./HyperpayStreamInfo";
import HyperpayCard from "./HyperpayCard";
import PaykassamaCard from "./PaykassamaCard";
import PaypalCard from "./PaypalCard";

interface Props {
  stream: ICourse;
  onClose: () => void;
  paymentInfo: ICoursePaymentInfo|null;
  visible: boolean;
}

const HyperpayModal: FC<Props> = ({visible, onClose, paymentInfo, stream}) => {
  const [order, setOrder] = useState<IOrder>();

  return (
    <Modal
      open={visible}
      center
      onClose={onClose}
      showCloseIcon={false}
      classNames={{root: 'payment-modal'}}
    >
      <div className='title-24 text-center pb-3 d-flex align-items-center justify-content-between'>
        <div></div>
        <div>{translate('Payment_info')}</div>
        <CloseBtn onClick={onClose}/>
      </div>
      <div className='payment-modal-content'>
        <HyperpayStreamInfo
          stream={stream}
          paymentInfo={paymentInfo}
        />
        {paymentInfo?.availablePaymentTypes.includes('hyperpay') &&
          <HyperpayCard stream={stream} paymentInfo={paymentInfo} order={order} setOrder={setOrder}/>
        }
        {paymentInfo?.availablePaymentTypes.includes('paykassma') &&
          <PaykassamaCard stream={stream} order={order} setOrder={setOrder}/>
        }
        {paymentInfo?.availablePaymentTypes.includes('paypal') &&
          <PaypalCard stream={stream} order={order} setOrder={setOrder} paymentType={EPaymentTypes.paypal} />
        }
        {paymentInfo?.availablePaymentTypes.includes('stripe') &&
          <PaypalCard stream={stream} order={order} setOrder={setOrder} paymentType={EPaymentTypes.stripe} />
        }
      </div>
    </Modal>
  );
}


export default observer(HyperpayModal);