export interface IError {
  code?: string;
  message?: string;
}

export interface ILang {
  name: string;
  native: string;
  rus: string;
}

export interface ILanguages {
  [name: string]: ILang;
}

export interface ITranslates {
  [name: string]: string;
}

export interface ICurrency {
  [name: string]: {
    name: string;
    symbol: string;
  };
}

export interface IIcons {
  [prop: string]: (size?: number, color?: string) => any;
}

export interface ISize {
  width: number;
  height: number;
}

export interface IFile {
  ext?: string;
  hash?: string;
  id: number;
  image_height?: number|null;
  image_width?: number|null;
  name?: string;
  path: string;
  path_768?: string|null;
  path_1080?: string|null;
  path_1334?: string|null;
  path_1920?: string|null;
  size?: number;
  type?: 'image'|'video';
  thumbnail?: null|IFile;
}

export interface ICameraPicture {
  base64: string;
  deviceOrientation: number;
  height: number;
  pictureOrientation: number;
  uri: string;
  width: number;
}

export interface IPagination {
  count?: number;
  current_page?: number;
  per_page?: number;
  total?: number;
  total_pages?: number;
}

export interface IFilters {
  search?: string;
  lang?: string;
  task?: string|number;
  need_translate?: boolean;
  pending?: boolean;
  page?: number|string;
  passed?: number;
}

export type TFiltersTypes = 'search'|'clear'|'lang'|'need_translate'|'pending'|'page'|'passed';


export enum EPaymentTypes {
  cash = 'cash',
  hyperpay = 'hyperpay',
  paykassma = 'paykassma',
  paypal = 'paypal',
  stripe = 'stripe',
}

export interface IOrder {
  order_id: string;
  custom_transaction_id: string;
  merchant_payment_link: string;
  price: string;
  currency: string;
  error: string;
}
