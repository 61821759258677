import React, {FC, useEffect, useState} from 'react';
import './content_body.css'
// import YouTube from 'react-youtube';
import ReactPlayer from 'react-player'
import {IMaterialData} from '../../interfaces/i_courses';
import EmptyList from '../empty_list';
import {addHostToPath} from '../../utils';
import File from '../file/File';
import VideoPlayer from '../video_player';
import ImagesModal from './ImagesModal';

interface Props {
  data?: IMaterialData[];
}

const ContentBody: FC<Props> = ({data}) => {
  const [visible, setVisible] = useState('');
  const [imagesList, setImagesList] = useState([]);

  useEffect(() => {
    if (data?.length) {
      const images: any = [];
      data.forEach((item, index) => {
        if (item.type === 'image') {
          images.push(addHostToPath(item.data));
        }
      });
      setImagesList(images);
    }
  }, [data]);

  const handleCloseModal = () => setVisible('');

  const handleImageClick = (element: any) => {
    if (element.type === 'image') {
      const activeIdx = (imagesList.findIndex(item => item === addHostToPath(element.data)) || 0) + '';
      setVisible(activeIdx);
    }
  }
  return (
    <div className="content-wrap" id="content-wrap">
      {data?.length
        ?
        data.map((element, i) => (
          <div key={i} className={`material-item material-item-${element.type}`}
               onClick={() => handleImageClick(element)}>
            {element.type === 'text' ? <div dangerouslySetInnerHTML={{__html: element.data}} /> : null}
            {element.type === 'image' ? <img src={addHostToPath(element.data)} alt="img" /> : null}
            {element.type === 'file' ? <File file={element.data} /> : null}
            {element.type === 'video' ? <VideoPlayer file={element.data} /> : null}
            {element.type === 'youtube' ? <ReactPlayer
              controls
                url={`https://youtu.be/${element.data}`}
              />
              : null}
            {element.type === 'vimeo' ? <ReactPlayer
              controls
                url={`https://vimeo.com/${element.data}`}
              />
              : null}
          </div>
        ))
        :
        <EmptyList className="h-100 w-100 mb-4" title="material_NO_CONTENT" />
      }
      <ImagesModal visible={visible} handleClose={handleCloseModal} images={imagesList} />
    </div>
  );
}

export default ContentBody;
