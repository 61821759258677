import React, {FC} from 'react';
import Spinner from '../../components/spinner';
import ErrorData from '../../components/ErrorData';
import MaterialType from '../../components/material_type';
import {ICourseMaterial} from '../../interfaces/i_courses';

interface Props {
  hiddenRight?: boolean;
  loading?: boolean;
  className?: string;
  error?: any;
  material?: ICourseMaterial;
  number?: any;
  leftContent: any;
  rightContent: any;
  nextComponent: any;
}

const CourseMaterialWrapper: FC<Props> = ({
                                            className,
                                            loading,
                                            error,
                                            material,
                                            number,
                                            leftContent,
                                            rightContent,
                                            hiddenRight,
                                            nextComponent,
                                          }) => {
  return (
    <div className={`course-content${hiddenRight ? ' course-content-right-hidden' : ''} ${className || ''}`}>
      <div className="course-content-left">
        <div className="course-content-numeric">{number}</div>
        <div className="course-content-padder">
          <MaterialType material={material} />
          <h3 className="title-32">{material?.name}</h3>
          {loading
            ? <Spinner className="position-relative" />
            : error
              ?
              <ErrorData title={'TRY_RELOAD_PAGE'} />
              :
              leftContent()
          }
        </div>
        {nextComponent()}
      </div>
      {!loading && !error
        ? <div className="course-content-right">
          <div className="course-content-right-sticky">
            {rightContent()}
          </div>
          {nextComponent()}
        </div>
        : null
      }
    </div>
  );
}

export default CourseMaterialWrapper;
