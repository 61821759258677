import React, {FC} from 'react';
import {ButtonHTMLAttributes} from 'react';
import Spinner from '../spinner';

interface Props extends ButtonHTMLAttributes<any> {
  type?: 'submit' | 'button';
  className?: string;
  loading?: boolean;
}

const MutedBtn: FC<Props> = ({
                                 type,
                                 loading,
                                 className,
                                 children,
                                 ...props
                               }) => {
  return (
    <button
      className={`btn btn-muted${loading ? ' loading' : ''}${props.disabled ? ' disabled' : ''} ${className || ''}`}
      type={type || 'button'} {...props}>
      {loading ? <Spinner className="position-relative" small /> : children}
    </button>
  );
}

export default MutedBtn;
