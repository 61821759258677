import React, {FC, useEffect} from 'react';
import {getLocalDate, getSecondsUntilDate, translate} from '../../../utils';
import RenderSvg from '../../../components/RenderSvg';
import lock_svg from '../../../assets/icons/lock2.svg';
import start_svg from '../../../assets/icons/start.svg';
import {observer} from 'mobx-react';

interface Props {
  date?: string;
  start?: boolean;
  hide?: boolean
  updateSider?: () => void;
}

const SiderDate: FC<Props> = ({date, hide, start, updateSider}) => {
  useEffect(() => {
    let _timer: any;
    if (date && updateSider) {
      const timestamp = getSecondsUntilDate(date);
      if (timestamp > 0 && !hide) {
        const time = (timestamp * 1000) + 2500;
        _timer = setTimeout(updateSider, time);
      }
    }
    return () => {
      clearTimeout(_timer);
    }
  }, [date, hide]);

  if (hide || !date) return null;
  return (
    <div className="sider-item sider-date">
      <div className="sider-numeric"><RenderSvg path={start ? start_svg : lock_svg} size={20} /></div>
      <span>{translate(start ? 'Start_date' : 'BLOCK_UNTIL')}</span>
      <span className="ps-2 ps-xl-3 pe-2">{getLocalDate(date)}</span>
      <span className="muted">{getLocalDate(date, 'HH:mm')}</span>
    </div>
  );
}


export default observer(SiderDate);
