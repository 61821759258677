import React, {FC, useState} from 'react';
import './additional_material.css'
import {IMaterialData} from '../../../interfaces/i_courses';
import file_svg from '../../../assets/icons/file.svg'
import link_svg from '../../../assets/icons/link.svg'
import RenderSvg from '../../../components/RenderSvg';
import {MutedBtn, PrimaryBtn} from '../../../components/buttons';
import {addHostToPath, translate} from '../../../utils';
import check from '../../../assets/icons/check.svg';

interface Props {
  material: IMaterialData;
  handleViewExtraMaterial: (uuid?: string, unread?: number) => Promise<void>;
}

const iconsMap: any = {link: link_svg, file: file_svg};

const AdditionalMaterialItem: FC<Props> = ({material, handleViewExtraMaterial}) => {
  const [loading, setLoading] = useState(false);

  const handleClick = (unread?: number) => async () => {
    try {
      setLoading(true);
      await handleViewExtraMaterial(material?.uuid, unread);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="extra-materials-item">
      <a
        onClick={handleClick(0)}
        href={material?.type === 'link' ? material.data?.link : addHostToPath(material.data)}
        download={material?.type === 'file' ? (material.data?.info?.filename || material.data?.uuid) : undefined}
        type="application/octet-stream" rel="noopener noreferrer" target="_blank" className="extra-item-content">
        <div className="extra-item-icon">
          <RenderSvg path={iconsMap[material?.type]} size={24} />
        </div>
        <div className="extra-item-name">{material.data?.name}</div>
      </a>
      {!material.ready
        ?
        <PrimaryBtn onClick={handleClick(0)} loading={loading}>{translate('mark')}</PrimaryBtn>
        :
        <MutedBtn onClick={handleClick(1)} loading={loading}>
          <RenderSvg path={check} size={24} />
          <span>{translate('Viewed')}</span>
        </MutedBtn>
      }
    </div>
  );
}

export default AdditionalMaterialItem;
