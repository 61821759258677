import React, {FC} from 'react';
import {IUpload} from '../../../interfaces/i_upload';
import File from '../../file/File';
import {CloseBtn} from '../../buttons';

interface Props {
  files: IUpload[];
  onRemove: (uuid: string) => void;
}

const TaskAnswerFooterListFiles: FC<Props> = ({files, onRemove}) => {
  if (!files.length) return null;
  return (
    <>
      <div className='task-list-files'>
        {files.map(file => (
          <div key={file.uuid} className="position-relative d-flex align-items-center">
            <CloseBtn onClick={() => onRemove(file?.uuid)} small className="remove-task-file" />
            <File file={file} />
          </div>
        ))}
      </div>
      <div className="task-list-files-separator"/>
    </>

  );
}

export default TaskAnswerFooterListFiles;
