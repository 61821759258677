import React, {FC, useState} from 'react';
import {apiCreateOrder} from "../../../services/apiCourses";
import {EPaymentTypes, IOrder} from "../../../interfaces/i_app";
import {toast, translate} from "../../../utils";
import RootStore from "../../../store/RootStore";
import {ICourse, ICoursePaymentInfo} from "../../../interfaces/i_courses";
import {PrimaryBtn} from "../../../components/buttons";
import RenderSvg from "../../../components/RenderSvg";
import arrow from '../../../assets/icons/arrow-right.svg';
import {observer} from "mobx-react";
import geos from '../../../jsons/geos.json';

interface Props {
  stream: ICourse;
  setOrder: (order: IOrder) => void;
  paymentInfo: ICoursePaymentInfo|null;
}

const HyperpayInfoForm: FC<Props> = ({stream, setOrder, paymentInfo}) => {
  const [loading, setLoading] = useState(false);
  const [formInfo, setFormInfo] = useState({
    country: '',
    state: '',
    city: '',
    street: '',
    postcode: '',
  });

  const handleChange = (name: string) => (e: any) => {
    const value = e.target.value;
    setFormInfo(formInfo => {
      return {...formInfo, [name]: value};
    })
  }

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      const {user} = RootStore.userStore;
      const res = await apiCreateOrder({
        email: user.email!,
        stream_id: stream.id!,
        paymentType: EPaymentTypes.hyperpay,
        location: window.location.origin || window.location.href,
        ...formInfo,
      });
      const {redirect_url, order_id} = res || {};
      if (redirect_url) {
        window.location.href = redirect_url;
      } else if (order_id) {
        setOrder(res);
      } else {
        toast('Payment_error')
      }
    } catch (e) {
    } finally {
      setLoading(false)
    }
  }

  return (
    <form onSubmit={onSubmit} className='px-4 py-3 d-flex flex-column flex-grow-1'>
      <div className='flex-grow-1'>
        <div className="form-group pb-1">
          <label>{translate('country')}</label>
          <input
            required
            className="form-control"
            placeholder={translate('country_placeholder')}
            //@ts-ignore
            value={geos[formInfo?.country]?.native}
            onChange={handleChange('country')}
            list="country"
            name="country"
          />
          <datalist id="country">
            {Object.entries(geos).map(([key, value]) => (
              <option key={key} value={key}>({key}) {value.native}</option>
            ))}
          </datalist>
        </div>
        <div className="row pb-1">
          <div className="col-6">
            <div className="form-group">
              <label>{translate('state')}</label>
              <input
                name={'state'}
                required
                type="text"
                className="form-control"
                placeholder={translate('state_placeholder')}
                value={formInfo.state}
                onChange={handleChange('state')}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label>{translate('city')}</label>
              <input
                name={'city'}
                required
                type="text"
                className="form-control"
                placeholder={translate('city_placeholder')}
                value={formInfo.city}
                onChange={handleChange('city')}
              />
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col-6">
            <div className="form-group">
              <label>{translate('street')}</label>
              <input
                name='street'
                required
                type="text"
                className="form-control"
                placeholder={translate('street_placeholder')}
                value={formInfo.street}
                onChange={handleChange('street')}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label>{translate('postcode')}</label>
              <input
                name='postcode'
                required
                type="text"
                className="form-control"
                placeholder={translate('postcode_placeholder')}
                value={formInfo.postcode}
                onChange={handleChange('postcode')}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-end align-items-center pt-4 pt-lg-0' dir='auto'>
        <PrimaryBtn type={'submit'} className='w-auto' loading={loading}>
          <span dir="auto" className='text-nowrap px-4'>{translate('GO_TO')}</span>
          <RenderSvg path={arrow}/>
        </PrimaryBtn>
      </div>
    </form>
  );
}


export default observer(HyperpayInfoForm);