import React, {PureComponent} from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {ICourse} from '../interfaces/i_courses';
import {toast} from '../utils';
import {IPagination, IFilters, TFiltersTypes} from '../interfaces/i_app';
import Courses from '../containers/courses';
import {apiGetListCourses, apiMarkCourseAsPassed} from '../services/apiCourses';
import {observer} from 'mobx-react';
import {Header} from '../components/header';
import OgTags from '../components/OgTags';

interface MatchParams {
  page: string;
}

interface State {
  ready: boolean;
  preload: boolean;
  loading: boolean;
  error: any;
  data: ICourse[];
  pagination?: IPagination;
  filters: IFilters;
}

@observer
class CoursesPage extends PureComponent<RouteComponentProps<MatchParams>> {
  state: State = {
    ready: false,
    preload: false,
    loading: false,
    error: false,
    data: [],
    filters: {passed: 0},
  }

  async componentDidMount() {
    if (this.props.history.location.pathname === "/privacy") {
      this.checkPrivacy();
    } else {
      await this.get();
    }
  }

  checkPrivacy = () => {
    this.props.history.replace('/courses', {privacy: true});
    this.get();
  }

  get = async (page?: number | string) => {
    const {filters} = this.state;
    this.setState({loading: true});
    try {
      const res: any = await apiGetListCourses({
        ...filters,
        page: page || 1,
        limit: 20,
      });
      this.setState({data: res?.items, pagination: res?.meta, ready: true});
    } catch (e: any) {
      this.setState({error: e || true, ready: true});
      toast(e?.message || '');
    } finally {
      this.setState({preload: false, loading: false});
    }
  };

  changeFilter = async (key: TFiltersTypes, value: any) => {
    this.setState({filters: {...this.state.filters, [key]: value}}, this.get);
  };

  handlePassedCourse = async (id: number) => {
    try {
      this.setState({loading: true});
      await apiMarkCourseAsPassed(id);
      this.get();
    } catch (e: any) {
      toast(e.message);
      throw e;
    }
  }

  onReachEnd = async () => {

  }

  render() {
    return <>
      <OgTags />
      <Header  />
      <Courses
        {...this.state}
        handlePassedCourse={this.handlePassedCourse}
        changeFilter={this.changeFilter}
        onReachEnd={this.onReachEnd}
        get={this.get}
      />
    </>
  }
}

export default withRouter(CoursesPage);
