import React, {FC} from 'react';
import '../containers/auth/auth.css'
import {observer} from 'mobx-react';
import Logo from '../components/logo';
import RootStore from '../store/RootStore';
import AuthNavigation from '../navigation/AuthNavigation';
import Spinner from "../components/spinner";

const AuthPage: FC = () => {
  const {ready} = RootStore.appStore;
  return (
    <div className="login">
      <div className="login-main">
        <Logo/>
        <div className="login-content">
          {ready
            ?
            <AuthNavigation/>
            :
            <Spinner className='position-relative'/>
          }
        </div>

      </div>
      <div className="bg-wrap">
        <div className="bg"/>
      </div>
    </div>
  );
}


export default observer(AuthPage);
