import React, {FC, useCallback} from 'react';
import {ICoursesComponentProps} from '../../interfaces/i_courses';
import Spinner from '../../components/spinner';
import EmptyList from '../../components/empty_list';
import LoadingWrapper from '../../components/loading_wrapper';
import CoursesListItem from './CoursesListItem';
import {useHistory} from 'react-router-dom';

interface Props extends ICoursesComponentProps {

}

const CoursesList: FC<Props> = ({data, ready, preload, loading, handlePassedCourse}) => {
  const history: any = useHistory();

  const handleItemClick = useCallback((course) => {
    history.push(`/courses/${course.id}`, {course});
  }, []);

  return (
    <div className="courses-list">
      {!ready
        ? <Spinner />
        : <LoadingWrapper loading={loading} className="row g-4">
          {data?.length
            ?
            data.map((course, i) => (
              <CoursesListItem
                key={course.id}
                course={course}
                onClick={handleItemClick}
                handlePassedCourse={handlePassedCourse}
                index={i}
              />
            ))
            :
            <EmptyList />
          }
          {preload ? <Spinner className="position-relative" small /> : null}
        </LoadingWrapper>
      }
    </div>
  );
}

export default CoursesList;
