import React, {FC, memo} from 'react';
import {ICourseArticleComponentProps} from '../../interfaces/i_courses';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import ContentCourseAbout from './ContentCourseAbout';
import ContentCourseLesson from './ContentCourseLesson';
import ContentCourseTask from './ContentCourseTask';

interface Props extends ICourseArticleComponentProps {
  breadcrumbs?: boolean;
}

const CourseContent: FC<Props> = (props) => {
  return (
    <div className="course-content-wrap">
      {props.breadcrumbs
        ?
        <Breadcrumbs data={[
          {name: 'COURSES', path: `/courses`},
          {name: props.parent?.name || '', onClick: () => props.setSelected(null)},
          ...props?.selected ? [{name: props.selected.name}] : []
        ]} />
        :
        null
      }
      {!props.selected
        ?
        <ContentCourseAbout data={props.parent} getMaterialsList={props.getMaterialsList} />
        :
        <>
          {props.selected.type === 'lesson'
            ? <ContentCourseLesson
              loading={props.preload}
              updateData={props.updateMaterialData}
              setSelected={props.setSelected}
              data={props.selected} />
            : null
          }
          {props.selected.type === 'task'
            ? <ContentCourseTask
              preload={props.preload}
              updateData={props.updateMaterialData}
              setSelected={props.setSelected}
              data={props.selected} />
            : null
          }
        </>
      }
    </div>
  );
}

export default memo(CourseContent);
