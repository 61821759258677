import React, {FC, useCallback} from 'react';
import {ICourseArticleComponentProps} from '../../../interfaces/i_courses';
import TextTruncate from 'react-text-truncate';
import ChatLink from '../../../components/chat_link';
import {Progress} from '../../../components/progress';
import {getBgGradientClassName} from '../../../utils';

interface Props extends ICourseArticleComponentProps {
}

const CourseSiderHeader: FC<Props> = ({parent, selected, setSelected, progress, ready}) => {
  const handleClick = useCallback(() => {
    if (selected) setSelected(null);
  }, [selected]);
  return (
    <div className={`sider-item sider-header${!selected ? ' active' : ''} ${getBgGradientClassName(parent?.id)}`}
         onClick={handleClick}>
      <div className="sider-header-content">
        <div dir="auto">
          <TextTruncate
            line={2}
            element="h4"
            containerClassName="title"
            truncateText="…"
            text={parent?.name}
          />
        </div>
        <div className="sider-header-footer">
          <ChatLink small className="d-inline-flex" link={parent.chat_link} />
          <Progress progress={progress} />
        </div>
      </div>
    </div>
  );
}

export default CourseSiderHeader;
