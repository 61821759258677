import React, {ErrorInfo, PureComponent} from 'react'
import {RouteComponentProps, withRouter} from 'react-router-dom'
import {observer} from 'mobx-react';
import {translate} from '../utils';
import {Header} from '../components/header';

class ErrorBoundary extends PureComponent<RouteComponentProps> {
  state = {
    hasError: false,
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({hasError: true});
  }

  handlePress = () => {
    this.setState({hasError: false});
    this.props.history.replace('/');
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <Header />
          <div className="error-boundary text-center">
            <div className="error-boundary-img-wrap">
              {/*<img src={logo} alt='' width='200'/>*/}
            </div>
            <h1 className="title-32 pb-5">{translate('FATAL_ERR_TITLE')}</h1>
            <h5 className="medium-20 pb-5">{translate('FATAL_ERR_TEXT')}</h5>
            <button type="button" className="btn btn-primary" onClick={this.handlePress}>{translate('on_Main')}</button>
          </div>
        </>
      )
    }
    return this.props.children
  }
}


export default withRouter(observer(ErrorBoundary));
