import {api} from '../utils';
import {ICourse, ICourseMaterial, ICoursePaymentInfo, ICourseProgress} from '../interfaces/i_courses';
import {ITaskAnswer} from '../interfaces/i_tasks';

export const apiGetListCourses = async (data: any): Promise<ICourse[]> => {
  try {
    const res = await api('/stream', data, 'get');
    return res?.data?.streams;
  } catch (e: any) {
    throw e;
  }
}


export const apiGetCoursePaymentInfo = async (streamId: number): Promise<ICoursePaymentInfo> => {
  try {
    const res = await api(`/${streamId}/getLoginInfo`, {}, 'get');
    return res?.data;
  } catch (e) {
    throw e;
  }
};

export const apiCreateOrder = async (req: {email: string, stream_id: number, paymentType?: string, location?: string}) => {
  try {
    const res = await api(`/payment/createOrder`, req, 'post');
    return res?.data;
  } catch (e) {
    throw e;
  }
};

export const apiGetSingleCourse = async (id: any): Promise<ICourse[]> => {
  try {
    const res = await api(`/stream/${id}`, {}, 'get');
    return res?.data?.stream;
  } catch (e: any) {
    throw e;
  }
}

export const apiMarkCourseAsPassed = async (id: any): Promise<void> => {
  try {
    await api(`/stream/${id}/toPassed`, {}, 'post');
  } catch (e: any) {
    throw e;
  }
}

export const apiGetCourseMaterials = async (stream_id?: number | string, data?: any): Promise<{materials: ICourseMaterial[], progress: ICourseProgress}> => {
  try {
    const res = await api(`/stream/${stream_id}/getMaterials`, data || {}, 'get');
    return {materials: res?.data?.materials_stream?.items, progress: res?.data?.progress?.items};
  } catch (e: any) {
    throw e;
  }
}

export const apiGetSingleMaterial = async (stream_id?: number | string, material_id?: number | string): Promise<ICourseMaterial> => {
  try {
    const res = await api(`/stream/${stream_id}/material/${material_id}`, {}, 'get');
    return res?.data?.materialsstream;
  } catch (e: any) {
    throw e;
  }
}

export const apiLearnMaterial = async (stream_id?: number | string, material_id?: number | string, unread?: number): Promise<ICourseMaterial> => {
  try {
    const res = await api(`/stream/setReadyMaterial`, {stream_id, material_id, unread: unread || null}, 'post');
    return res?.data?.materials_stream?.items;
  } catch (e: any) {
    throw e;
  }
}

export const apiGetTaskChat = async (material_id?: number | string): Promise<ITaskAnswer[]> => {
  try {
    const res = await api(`/homework/getMessages`, {material_id}, 'get');
    return res?.data?.messages?.items;
  } catch (e: any) {
    throw e;
  }
}

export const apiTaskSendMessage = async (material_id?: number, req?: any): Promise<void> => {
  try {
    await api(`/homework/sendMessage`, {...req, material_id}, 'post');
  } catch (e: any) {
    throw e;
  }
}