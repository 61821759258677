import React, {FC, useCallback, useState} from 'react';

import HeaderProfile from './HeaderProfile';
import Notifications from './notifications';
import RenderSvg from '../RenderSvg';
import close_svg from '../../assets/icons/close.svg';
import back_arrow_svg from '../../assets/icons/back_arrow.svg';

interface Props {
  className?: string;
  showCenter?: boolean;
  close?: boolean;
  onHeaderBack?: () => void;
  handleGoFromNotif?: (item: any) => void;
  renderCenter?: any;
}

const HeaderSmall: FC<Props> = ({className, onHeaderBack, close, showCenter, renderCenter, handleGoFromNotif}) => {
  const [showHidden, setShowHidden] = useState('');

  const onClick = useCallback((type: string) => {
    setShowHidden(type);
  //   const body = document.body;
  //   if(type) {
  //     body.classList.add("body-overflow");
  //   } else {
  //     body.classList.remove("body-overflow");
  //   }
  }, []);
  return (
    <header className={`header-small${showCenter ? ' with-title' : ''} ${className || ''}`}>
      <button className="btn-circle" type="button" onClick={onHeaderBack}>
        <RenderSvg path={close ? close_svg : back_arrow_svg} />
      </button>
      {showCenter && renderCenter ? renderCenter() : null}
      <div className={`header-right${showHidden ? ` active ${showHidden}` : ''}`}>
        <Notifications handleShowHidden={onClick} visible={showHidden} handleGoFromNotif={handleGoFromNotif} />
        <HeaderProfile handleShowHidden={onClick} />
        <div className="active-btn-border" />
      </div>
    </header>
  );
}


export default HeaderSmall;
