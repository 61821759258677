import {api} from '../utils';
import {IUser} from '../interfaces/i_user';

export const apiGetUser = async (): Promise<IUser> => {
  if(!localStorage.token) {
    // eslint-disable-next-line no-throw-literal
    throw {message: 'No user'};
  }
  try {
    const res = await api('/student', {}, 'get');
    return res.data.student;
  } catch (e) {
    throw e;
  }
};

export const apiSendEmailToRecover = async (email: string): Promise<void> => {
  try {
    await api('/recoveryPassword', {email}, 'post');
  } catch (e) {
    throw e;
  }
}

export const apiCheckHash = async (hash: string): Promise<void> => {
  try {
    await api('/checkToken', {hash}, 'post');
  } catch (e) {
    throw e;
  }
}

export const apiUpdatePassByHash = async (req: any): Promise<IUser> => {
  try {
    const res = await api('/updatePassword', req, 'post');
    const user = res.data?.student;
    if(res.data?.accesstoken?.token) {
      localStorage.setItem('token', res.data.accesstoken.token);
    }
    return user;
  } catch (e) {
    throw e;
  }
}


export const apiUpdateUser = async <T>(user: T): Promise<IUser> => {
  try {
    const res = await api(`/student`, user, 'put');
    return res.data?.student;
  } catch (e) {
    throw e;
  }
};


export const apiLogin = async (data: any): Promise<IUser> => {
  try {
    const res =  await api(`/login`, data, 'post');
    const user = res.data?.student;
    if(res.data?.accesstoken?.token) {
      localStorage.setItem('token', res.data.accesstoken.token);
    }
    return user;
  } catch (e) {
    throw e;
  } finally {
  }
};

export const apiLogout = async (): Promise<void> => {
  try {
    api(`/student/logout`, {}, 'post');
  } catch (e) {
    throw e;
  } finally {
    localStorage.removeItem('token');
  }
};



