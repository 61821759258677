import React, {PureComponent} from 'react';
import YouTube from 'react-youtube';
import {Header} from '../components/header';
import ReactPlayer from 'react-player'

class WebinarPage extends PureComponent {

  state = {};

  render() {
    const videoId = 'Gxsu2D7YTV0'
    return (
      <div style={{color: 'red'}} >
        <Header />
        <main>
          {/*<ReactPlayer*/}
          {/*  url={`https://youtu.be/${videoId}`}*/}
          {/*/>*/}
          <YouTube
            videoId={videoId}
            opts={{
              height: '300',
              width: '682',
              playerVars: {
                autoplay: 0
              }
            }} />
          <iframe
            className='youtube-chat'
            src={`https://www.youtube.com/live_chat?v=${videoId}&embed_domain=localhost`}

          />
        </main>
      </div>
    );
  }
}

export default WebinarPage;
