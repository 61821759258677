import React, {FC} from 'react';
import {ICourseArticleComponentProps} from '../../interfaces/i_courses';
import ContentCourseAbout from '../course_article/ContentCourseAbout';
import SiderMaterials from '../course_article/course_sider/SiderMaterials';

interface Props extends ICourseArticleComponentProps {
  secondTypeVisible: boolean;
  handleChangeTypeClick: () => void;
  handleSiderClick: (material: any) => void;
}

const CourseNotStarted: FC<Props> = (props) => {
  return (
    <main className="course-article__small">
      {!props.secondTypeVisible
        ?
        <ContentCourseAbout
          data={props.parent}
          handleMaterialClick={props.handleChangeTypeClick}
          getMaterialsList={props.getMaterialsList}
        />
        :
        <SiderMaterials {...props} setSelected={props.handleSiderClick} />
      }
    </main>
  );
}

export default CourseNotStarted;
