import React, {FC} from 'react';
import RenderSvg from '../RenderSvg';
import plus from '../../assets/icons/plus.svg';

interface Props {
  onClick?: () => void;
  small?: boolean;
  className?: string;
}

const CloseBtn: FC<Props> = ({onClick,small, className}) => {
  return (
    <button type="button" className={`btn btn-circle remove${small ? ' btn-circle-small' : ''} ${className || ''}`} onClick={onClick}>
      <RenderSvg path={plus} size={small ? 14 : 16} rotate={45} />
    </button>
  );
}

export default CloseBtn;
