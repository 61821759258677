import React, {FC, useEffect, useState} from 'react';

import FormInput from '../../components/form_input/FormInput';
import {PrimaryBtn} from '../../components/buttons';
import {observer} from 'mobx-react';
import {toast, translate} from '../../utils';
import {IUser} from '../../interfaces/i_user';
import ProfileLangSelect from './ProfileLangSelect';

interface Props {
  handleUpdateProfile: (profile: any) => void;
  closeEdit: (e: any) => void;
  visible: boolean;
  user: IUser;
}

const ProfileForm: FC<Props> = ({handleUpdateProfile, closeEdit, visible, user}) => {
  const [form, setForm] = useState<any>({email: '', name: ''});
  const [changes, setChanges] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setForm({email: user.email, name: user.name});
  }, [visible]);

  const handleChange = (key: string, value: string) => {
    setChanges(true);
    setForm((prevState: any) => ({...prevState, [key]: value}));
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      await handleUpdateProfile(form);
    } catch (e: any) {
      toast(e.message);
    } finally {
      setLoading(false);
    }
  }
  return (
    <div className="profile-form animate__fadeInLeft fadeInLeft animate__animated">
      <form onSubmit={handleSubmit}>
        <FormInput
          visible={true}
          onChange={handleChange}
          value={form.name}
          name="name"
          label="FIRST_NAME"
        />
        <FormInput
          visible={true}
          onChange={handleChange}
          value={form.email}
          name="email"
        />
        <ProfileLangSelect />
        <div className='mt-3 mb-3'>
        {changes
          ?
          <>
            <PrimaryBtn type="submit" title="Save" loading={loading} />
            <button type="button" className="btn btn-transparent mt-2 mb-2"
                    onClick={closeEdit}>{translate('Cancel')}</button>
          </>
          :
          <button type="button" className="btn btn-transparent" onClick={closeEdit}>{translate('Back')}</button>
        }
        </div>
      </form>
    </div>
  );
}


export default observer(ProfileForm);
