import React, {FC, useState} from 'react';
import {Link} from 'react-router-dom';
import {observer} from 'mobx-react';
import {translate} from '../../utils';
import {PrimaryBtn} from '../../components/buttons';
import FormInput from '../../components/form_input/FormInput';

type TFields = 'email' | 'password';

interface Props {
  title: string;
  errorTitle?: string;
  btnTitle?: string;
  description?: string;
  onSubmit: (e: any) => void;
  onCancel?: boolean;
  fields: TFields[];
}

const AuthForm: FC<Props> = ({title, description, btnTitle, onSubmit, fields, onCancel, errorTitle}) => {
  const [form, setForm] = useState({email: '', password: ''});
  const [error, setError] = useState<any>(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (key: string, value: string) => {
    setForm(prevState => ({...prevState, [key]: value}));
  }

  const onFinish = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      await onSubmit(form);
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="auth-form">
      <form onSubmit={onFinish}>
        <div className={`auth-title${errorTitle && error ? ' error' : ''}`}>
          <h3>{translate(error && errorTitle ? errorTitle : title)}</h3>
        </div>
        {description ? <div className="auth-description">{translate(description)}</div> : null}
        <FormInput
          visible={fields.includes('email')}
          onChange={handleChange}
          value={form.email}
          name="email"
        />
        <FormInput
          visible={fields.includes('password')}
          onChange={handleChange}
          value={form.password}
          name="password"
        />
        <div className="position-relative" style={{paddingTop: onCancel ? 8 : 28}}>
          {btnTitle
            ?
            <PrimaryBtn type="submit" loading={loading}>
              {translate(btnTitle)}
            </PrimaryBtn>
            :
            null
          }
          {onCancel
            ?
            <Link to={'/'} className="btn text-decoration-none mt-3 p-0">{translate('Cancel')}</Link>
            :
            <Link to={'/recovery'} className="forgot-password">{translate('forgot_password')}</Link>
          }
        </div>
      </form>
    </div>
  );
}


export default observer(AuthForm);
