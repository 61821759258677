import React, {FC, useCallback, useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import HeaderProfile from './HeaderProfile';
import Notifications from './notifications';
import Logo from '../logo';

interface Props {
  className?: string;
  handleGoFromNotif?: (material: any) => void;
}



const Header: FC<Props> = ({className, handleGoFromNotif}) => {
  const [showHidden, setShowHidden] = useState('');
  const history:any = useHistory();

  useEffect(() => {
    if (history.location.state?.privacy) {
      setShowHidden('profile');
    }
  }, [history]);

  const onClick = useCallback((type: string) => {
    setShowHidden(type);
  }, []);
  return (
    <header className={className || ''}>
      <Link to={'/courses'} className="home-link">
        <Logo />
      </Link>
      <div className={`header-right${showHidden ? ` active ${showHidden}` : ''}`}>
        <Notifications handleShowHidden={onClick} visible={showHidden} handleGoFromNotif={handleGoFromNotif}/>
        <HeaderProfile handleShowHidden={onClick} />
        <div className="active-btn-border" />
      </div>
    </header>
  );
}


export default Header;
