import React, {FC} from 'react';
import './course_material.css';
import ContentCourseLesson from '../course_article/ContentCourseLesson';
import ContentCourseTask from '../course_article/ContentCourseTask';
import {ICourseMaterial} from '../../interfaces/i_courses';

interface Props {
  ready?: boolean;
  preload?: boolean;
  material: ICourseMaterial;
  handleNextClick: (data: any) => void;
  handleUpdateMaterial: () => void;
}

const CourseMaterial: FC<Props> = ({material, handleNextClick, handleUpdateMaterial, ready, preload}) => {
  if(!material) return null;
  return (
    <main className="course-article__small">
      <>
        {material.type === 'lesson'
          ? <ContentCourseLesson
            loading={!ready}
            updateData={handleUpdateMaterial}
            setSelected={handleNextClick}
            data={material} />
          : null
        }
        {material.type === 'task'
          ? <ContentCourseTask
            updateData={handleUpdateMaterial}
            setSelected={handleNextClick}
            data={material} />
          : null
        }
      </>
    </main>
  );
}

export default CourseMaterial;
