import React, {FC, useState} from 'react';
import RenderSvg from '../../RenderSvg';
import {observer} from 'mobx-react';
import TextareaAutosize from 'react-textarea-autosize';
import {toast, translate} from '../../../utils';
import file from '../../../assets/icons/file.svg';
import InputFile from '../../inputFile';
import {IUpload} from '../../../interfaces/i_upload';
import TaskAnswerFooterListFiles from './TaskAnswerFooterListFiles';
import {PrimaryBtn} from '../../buttons';
import {apiTaskSendMessage} from '../../../services/apiCourses';

let _timer: any;

const addBottomPaddingToChat = () => {
  clearTimeout(_timer);
  _timer = setTimeout(() => {
    const taskChat: any = document.querySelector('.task-content');
    const footerHeight: any = document.querySelector('.task-answer-footer')?.clientHeight;
    taskChat.style.paddingBottom = footerHeight - 120 + 'px';
  },100);
}

interface Props {
  taskId?: number;
  updateList: () => void;
}

const TaskAnswerFooter: FC<Props> = ({taskId, updateList}) => {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<any[]>([]);
  const [message, setMessage] = useState('');

  const handleChangeMessage = (e: any) => {
    setMessage(e.target.value);
    addBottomPaddingToChat();
  }

  const handleAddFile = (file: IUpload) => {
    setFiles(prevState => {
      return [...prevState, file];
    });
    addBottomPaddingToChat();
  }
  const handleRemoveFile = (uuid: string) => {
    setFiles(prevState => {
      return prevState.filter(file => file.uuid !== uuid);
    });
    addBottomPaddingToChat();
  }

  const onSubmit = async (e: any) => {
    e.preventDefault();
    if (!message) return null;
    setLoading(true);
    try {
      await apiTaskSendMessage(taskId, {message, files});
      await updateList();
      setMessage('');
      setFiles([]);
      addBottomPaddingToChat();
    } catch (e: any) {
      toast(e.message);
    } finally {
      setLoading(false);
    }
  }
  return (
    <div className="task-answer-footer">
      <form onSubmit={onSubmit}>
        <TaskAnswerFooterListFiles files={files} onRemove={handleRemoveFile} />
        <div className="task-answer-footer-input-line">
          <div className='task-answer-footer-message-area'>
          <TextareaAutosize
            dir='auto'
            className="input"
            maxRows={3}
            placeholder={translate('REPLY_TO_CURATOR')}
            value={message}
            onChange={handleChangeMessage}
          />
          </div>
          <InputFile value={null} onChange={handleAddFile} className="btn btn-muted task-answer-file" multiple accept="image/*;video/*;capture=camcorder">
            <RenderSvg path={file} size={20} />
          </InputFile>
        </div>
        <PrimaryBtn loading={loading} disabled={!message} type="submit">{translate('SEND_TO_TEST')}</PrimaryBtn>
      </form>
    </div>
  );
}


export default observer(TaskAnswerFooter);
