import {api} from '../utils';

export const apiGetLanguages = async () => {
  try {
    const res = await api('/translate/langs', {status: 1}, 'get');
    return res?.data?.langs?.items;
  } catch (e: any) {
  }
};
export const apiGetTranslates = async (lang?: string) => {
  try {
    const res = await api('/translate/lang', {lang}, 'get');
    return res?.data?.lang?.items;
  } catch (e: any) {
  }
};

export const appCheckTokenInQuery = async () => {
  try {
    const host = ``;
    const res = await api('/translate/lang', {host}, 'get');
    return res?.data?.lang?.items;
  } catch (e: any) {
  }
};

export const apiGetStreamRates = async (streamId: number)  => {
  try {
    const res = await api(`/stream/${streamId}/getRates`, {}, 'get');
    return  res?.data;
  } catch (e) {
    throw e;
  }
};