import React, {FC} from 'react';
import './loading_wrapper.css'
import Spinner from '../spinner';

interface Props {
  className?: string;
  loading?: boolean;
}

const LoadingWrapper: FC<Props> = ({className, loading, children}) => {
  return (
    <div className={`loading-wrapper${loading ? ' loading' : ''}`}>
      <div className={`loading-wrapper-content ${className || ''}`}>
        {children || null}
      </div>
      {loading ? <Spinner visible={loading} /> : null}
    </div>
  );
}

export default LoadingWrapper;
