import React, {PureComponent} from 'react'
import CropperJS from 'react-cropper';
import {Modal} from 'react-responsive-modal';
import 'cropperjs/dist/cropper.css';
import {apiUploadBeginPartial, apiUploadChunkPartial} from '../../services/apiUpload';
import {observer} from 'mobx-react';
import {toast, translate} from '../../utils';
import Spinner from '../spinner';
import {IUpload} from '../../interfaces/i_upload';
import {PrimaryBtn} from '../buttons';

interface Props {
  loading?: boolean
  disabled?: boolean
  cb: (image: IUpload) => Promise<void>
  width?: number
  className?: string
  ratio?: undefined | number
}

interface State {
  visible: boolean
  uploadImage: null,
  finalImage: null,
  cropperVisible: boolean,
  loadingBtn: boolean,
  image_title: string
  image_type: string
  image_size: any
  progress: number
}


class UploadImage extends PureComponent<Props> {
  inputFile: any = null;
  _cropper: any = null;

  state: State = {
    visible: false,
    uploadImage: null,
    finalImage: null,
    cropperVisible: false,
    loadingBtn: false,
    progress: 0,
    image_title: '',
    image_type: '',
    image_size: '',
  };

  handleUpload = async (e: any) => {
    this.setState({visible: true});
    let file = e.target.files[0];
    let reader = new FileReader();
    let fd = new FormData();
    fd.append(`file`, file);
    reader.onloadend = () => {
      this.setState({
        uploadImage: reader.result,
        image_title: file.name,
        image_type: file.type,
        image_size: file.size,
      }, () => this.setState({cropperVisible: true}));
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    this.inputFile.value = ''
  };

  handleOk = async () => {
    this.setState({loadingBtn: true});
    const finalImage = this._cropper?.getCroppedCanvas({
      width: this.props.width || 1200,
      height: this.props.width || 1200,
    }).toDataURL();
    let base64Data: string = finalImage.replace('data:image/png;base64,', '');
    const fileSize = window.atob(base64Data).length;
    let id: string;
    const chunkReaderBlock = () => {
      apiUploadChunkPartial({id, chunk: base64Data})
        .then((res: any) => {
          this.setState({loadingBtn: false, visible: false});
          this.props.cb(res.file);
        })
        .catch((e: any) => {
          toast(e.message);
        });
    };

    apiUploadBeginPartial({
      fileSize: fileSize,
      chunkSize: fileSize,
      fileType: this.state.image_type,
      fileName: this.state.image_title,
    })
      .then((res: any) => {
        id = res.id;
        chunkReaderBlock();
      })
      .catch((e: any) => {
        toast(e.message);
      });
  };

  handleCancel = (): void => {
    this.setState({visible: false, uploadImage: null, finalImage: null, loadingBtn: false});
  };

  render() {
    const {ratio = 4 / 4} = this.props;
    return (
      <div onClick={(e) => e.stopPropagation()}>
        {!this.props.loading ?
          <label className="m-0">
            <input
              disabled={this.props.disabled}
              type="file"
              accept="image/*"
              ref={(input) => this.inputFile = input}
              className="d-none"
              onChange={this.handleUpload} />
            {this.props.children}
          </label>
          : <div className={`button_upload ${this.props.className || ''}`}>
            {/*<Icon type="loading" />*/}
          </div>}
        <Modal
          closeOnOverlayClick={false}
          open={this.state.visible}
          onClose={this.handleCancel}
          center
          showCloseIcon={false}
          // afterClose={() => this.setState({cropperVisible: false})}
        >
          {this.state.cropperVisible ? <CropperJS
              //@ts-ignore
              onInitialized={(cropper) => this._cropper = cropper}
              src={this.state.uploadImage || ''}
              style={{maxHeight: 400, width: '100%'}}
              zoomable={false}
              guides={false}
              aspectRatio={ratio}
            />
            :
            <div style={{height: 400}} className="position-relative d-flex align-items-center"><Spinner /></div>
          }
          <div className="d-flex pt-4 align-items-center">
            <button type="button" className="btn me-3" onClick={this.handleCancel}>{translate('Cancel')}</button>
            <PrimaryBtn
              onClick={this.handleOk}
              type="button" className="btn btn-primary ms-3"
              disabled={!this.state.cropperVisible}
              loading={this.state.loadingBtn}
              title={'Save'}
            />
          </div>
        </Modal>
      </div>
    );
  }
}


export default observer(UploadImage);
