import React, {FC} from 'react';
import './course_article.css'
import {ICourseArticleComponentProps} from '../../interfaces/i_courses';
import CourseSider from './course_sider';
import CourseContent from './CourseContent';

interface Props extends ICourseArticleComponentProps {

}

const CourseArticle: FC<Props> = (props) => {
  if(!props.ready) return null;
  return (
    <main>
      <div className="course">
        <CourseSider {...props} />
        <CourseContent breadcrumbs {...props} />
      </div>
    </main>
  );
}

export default CourseArticle;
