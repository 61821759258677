import axios from 'axios';
import logo from '../assets/images/logo.png';
import favicon from '../assets/images/favicon.png';
import logo_small from '../assets/images/logo_small.png';
import binare_logo from '../assets/images/binare_logo.png';
import binare_logo_small from '../assets/images/binare_logo_small.png';
import binare_favicon from '../assets/images/binare_favicon.png';

const host = window.location.host;
let endpoint: string;
let DOC_TITLE: string = 'Futuris';
let isLogo: boolean = true;
let LOGO: any = logo;
let LOGO_SMALL: any = logo_small;
let FAVICON: any = favicon;

if (host.includes('futuris.school')) {
  endpoint = 'https://api.futuris.school/member';
} else if (host.includes('edway.space')) {
  endpoint = 'https://api.edway.space/member';
  DOC_TITLE = 'Edway';
  isLogo = false;
} else if (host.includes('cursodetrading.org')) {
  endpoint = 'https://api.cursodetrading.org/member';
  DOC_TITLE = 'Opciones binarias';
  FAVICON = binare_favicon;
  LOGO = binare_logo;
  LOGO_SMALL = binare_logo_small;
} else {
  endpoint = 'https://api.gycdev.uk/member';
}

const HOST: string = endpoint.replace('/member', '');
export {endpoint, HOST, DOC_TITLE, isLogo, FAVICON, LOGO, LOGO_SMALL}

export type TMethods = 'post' | 'get' | 'put' | 'delete';

export async function api(path: string, args: any, method: TMethods = 'post', silent: boolean = false): Promise<any> {
  let token = localStorage.token;
  const params = {...args, token};
  try {
    const response = await axios[method](`${endpoint}${path}`, method === 'get' || method === 'delete' ? {params} : params);
    return response?.data;
  } catch (error: any) {
    console.log(error);
    if (error.code === 401 && token) {
      window.location.reload();
      localStorage.removeItem('token');
    }
    if (!silent) throw error;
  } finally {
  }
}
