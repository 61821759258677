import React, {FC} from 'react';
import './tabs.css'
import {translate} from '../../utils';
import {observer} from 'mobx-react';

interface Props {
  tabs: string[];
  active: number;
  onChange: (tab: number) => void;
  className?: string;
}


const Tabs: FC<Props> = ({tabs, active, onChange, className}) => {
  const handleClick = (i: number) => () => {
    onChange(i);
  }
  return (
    <div className={`tabs ${className || ''}`}>
      {tabs.map((tab, i) => (
        <div key={i} className={`tab-item${active === i ? ' active' : ''}`} onClick={handleClick(i)}>
          {translate(tab)}
        </div>
      ))}
    </div>
  );
}


export default observer(Tabs);
