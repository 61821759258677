import React, {FC, useMemo} from 'react';
import {observer} from 'mobx-react';
import {translate} from '../../utils';

interface Props {
  time?: number;
  text: string;
  disabled?: boolean;
}

const TimerItem: FC<Props> = ({text, time = 0, disabled}) => {
  const resTime = useMemo(() => {
    return time < 10 ? ('0' + time) : time;
  }, [time]);
  return (
    <div className={`timer-item${disabled ? ' disabled' : ''}`}>
      <div className="timer-item-scoreboard"><span>{resTime}</span></div>
      <div className="timer-item-title">{translate(text)}</div>
    </div>
  );
}


export default observer(TimerItem);
