import React, {FC, useEffect, useState} from 'react';
import {apiGetStreamRates} from "../../../services/apiApp";
import HyperpayStreamInfoListItem from "./HyperpayStreamInfoListItem";
import DateInfo from "../../../components/date_info";
import {ICourse, ICoursePaymentInfo, ICourseRate} from "../../../interfaces/i_courses";
import {translate} from "../../../utils";
import LoginIcon from "../../auth/LoginIcon";

interface Props {
  stream: ICourse;
  paymentInfo: ICoursePaymentInfo|null;
}

const HyperpayStreamInfo: FC<Props> = ({stream, paymentInfo}) => {
  const [ready, setReady] = useState(false);
  const [ratesVisible, setRatesVisible] = useState(false);
  const [rates, setRates] = useState<ICourseRate[]>([]);
  const [courseInfo, setCourseInfo] = useState<ICourse|undefined>();

  useEffect(() => {
    getRates();
  }, [])

  const getRates = async () => {
    try {
      const rates = await apiGetStreamRates(stream.id!);
      setRates(rates);
      setReady(true);
    } catch (e) {

    }
  }


  return (
    <div className={`payment-modal-info${courseInfo ? ' info-visible' : ''}`}>
      <div className='d-flex flex-grow-1 flex-column'>
        <HyperpayStreamInfoListItem
          stream={stream}
          own
          price={paymentInfo?.price!}
          currency={paymentInfo?.currency!}
          toggleVisible={setCourseInfo}
          // @ts-ignore
          visible={stream?.id === courseInfo?.id!}
        />
        {ready && rates?.length
          ?
          ratesVisible
            ?
            <div className="payment-modal-info-list">
              <div className='d-flex px-3 pb-1 pt-3 justify-content-between'>
                <div>{translate('ALL_RATES')}</div>
                <div className='payment-modal-info-item-link' onClick={() => {
                  setRatesVisible(false);
                  setCourseInfo(undefined);
                }}>{translate('HIDE')}</div>
              </div>
              {rates.map(rate => (
                <HyperpayStreamInfoListItem
                  key={rate.id}
                  stream={rate?.stream}
                  price={rate?.price}
                  currency={rate?.currency}
                  toggleVisible={setCourseInfo}
                  // @ts-ignore
                  visible={rate?.stream?.id === courseInfo?.id!}
                />
              ))}
            </div>
            :
            <div onClick={() => setRatesVisible(true)} className='payment-modal-info-all-rates'>
              {translate('all_rates')}
            </div>
          :
          null
        }
      </div>
    </div>
  );
}

export default HyperpayStreamInfo;