import React, {FC} from 'react';
import './chat_link.css';
import RenderSvg from '../RenderSvg';
import chat from '../../assets/icons/chat.svg';
import arrow from '../../assets/icons/arrow-right-dark.svg';
import {observer} from 'mobx-react';
import {translate} from '../../utils';
import SVG from 'react-inlinesvg';

interface Props {
  link?: string;
  text?: boolean;
  small?: boolean;
  className?: string;
}

const ChatLink: FC<Props> = ({link, text, small, className}) => {
  if(!link) return null;
  return (
    <a href={link} target='_blank'  rel="noreferrer" className={`chat-link${small ? ' small' : ''} ${className || ''}`}>
      <div className="btn btn-primary"><RenderSvg path={chat} size={small ? 14 : 16} /></div>
      {text
        ?
        <div className="chat-link-text">
          <span>{translate('GROUP_CHAT')}</span>
          <div className="chat-link-arrow">
            <SVG src={arrow} />
          </div>
        </div>
        :
        null
      }
    </a>
  );
}


export default observer(ChatLink);
