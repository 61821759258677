import React, {FC} from 'react';
import {ICourseArticleComponentProps} from '../../../interfaces/i_courses';
import EmptyList from '../../../components/empty_list/EmptyList';
import SiderMaterialsItem from './SiderMaterialsItem';


interface Props extends ICourseArticleComponentProps {
  handleScroll?: any;
  className?: string;
}

const SiderMaterials: FC<Props> = ({ready, className, handleScroll, materials, ...props}) => {
  if (!ready) return null;
  return (
    <div className={`sider-materials-list ${className || ''}`} onScroll={handleScroll} id={'sider-materials'}>
      <div className="sider-materials-list-content">
        {materials?.length
          ? materials.map(item => (
            <SiderMaterialsItem key={item.id} {...props} material={item} />
          ))
          :
          <EmptyList />
        }
      </div>
    </div>
  );
}

export default SiderMaterials;
