import React, {ChangeEvent, useCallback, useState} from 'react';
import './inputFile.css';
import {toast} from '../../utils';
import {apiUploadBeginPartial, apiUploadChunkPartial} from '../../services/apiUpload';
import {IUpload} from '../../interfaces/i_upload';

interface InputFileProps {
  value: IUpload | null;
  onChange(file: IUpload | null): void;
  accept?: string;
  multiple?: boolean;
  resize?: number;
  text?: string;
  className?: string;
  children?: any,
}



const InputFile = ({
                     value,
                     multiple,
                     onChange,
                     resize,
                     accept = '*/*',
                     className,
                     children,
                   }: InputFileProps) => {
  const [progress, setProgress] = useState<number>(0);
  const [uploading, setUploading] = useState<boolean>(false);

  const change = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    // const file: any = e.target.files?.[0];
    const files: any = e.target.files;
    // e.target.value = '';
    if (value) onChange(null);
    // const fileSize = file.size;
    const chunkSize = 1024 * 512;
    let offset = 0;

    const chunkReaderBlock = (file: any, id: string) => {
      const reader: any = new FileReader();
      const blob = file.slice(offset, chunkSize + offset);
      reader.onload = () => {
        const chunk = reader.result?.split(',')[1];
        apiUploadChunkPartial({
          id,
          chunk,
        })
          .then((res: any) => {
            setProgress(res.progress);
            offset += chunkSize;
            if (res.progress < 100) {
              chunkReaderBlock(file, id);
            } else {
              e.target.value = '';
              setUploading(false);
              setProgress(0);
              onChange(res.file);
            }
          })
          .catch((e: any) => {
            setUploading(false);
            toast(e.message);
          });
      };
      reader.readAsDataURL(blob);
    };

    setProgress(0);
    setUploading(true);
    Array(files?.length || 1).fill({}).forEach((item, i) => {
      const file: any = e.target.files?.[i];
      apiUploadBeginPartial({
        fileSize: file.size,
        chunkSize,
        fileType: file.type,
        fileName: file.name,
        resize,
      })
        .then((res: any) => {
          const id = res.id;
          chunkReaderBlock(file, id);
        })
        .catch((e: any) => {
          setUploading(false);
          toast(e.message);
        });
    });


  }, [value]);

  return (
    <div className={`input-file ${className || ''}`}>
      {(uploading) && (
        <div className="input-file__progress">
          <div className="input-file__progress__bar" style={{width: `${progress}%`}} />
        </div>
      )}
      <label>
        <input type="file" accept={accept} onChange={change} multiple={multiple} />
        {children || null}
      </label>
    </div>
  );
};

export default InputFile;
export {InputFile};
