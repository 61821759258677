import React, {FC} from 'react';
import {observer} from 'mobx-react';
import SVG from 'react-inlinesvg';
import './empty_list.css';
import {translate} from '../../utils';
import box from '../../assets/icons/applications/box.svg';

interface Props {
  title?: string;
  className?: string;
  book?: boolean
}

const EmptyList: FC<Props> = ({title, className}) => {
  return (
    <div className={`empty-list ${className || ''}`}>
      <SVG src={box} />
      <div className="empty-list-text">{translate(title || 'EMPTY_LIST')}</div>
    </div>
  );
}


export default observer(EmptyList);
