import React, {FC} from 'react';
import './avatar.css'
import {addHostToPath} from '../../utils';
import {IUser} from '../../interfaces/i_user';
import RenderSvg from '../RenderSvg';
import camera from '../../assets/icons/camera.svg';
import Cropper from '../cropper/Cropper';

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>{
  user?: IUser;
  small?: boolean;
  handleEdit?: (img: any) => Promise<void>;
  className?: string;
}

const Avatar: FC<Props> = ({user, small, className, handleEdit, ...props}) => {
  const letter = (user?.name || user?.email || '')[0] || '';
  return (
    <div className={`avatar${small ? ' small' : ''} ${className || ''}`} {...props}>
      {user?.avatar
        ?
        <img src={addHostToPath(user?.avatar)} alt="ava" />
        :
        <div className="no-avatar">
          {letter}
        </div>
      }
      {handleEdit
        ?
        <Cropper cb={handleEdit}>
          <div className="avatar-edit">
            <RenderSvg path={camera} size={24} />
          </div>
        </Cropper>
        :
        null
      }
    </div>
  );
}


export default Avatar;
