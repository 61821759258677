import React, {PureComponent} from 'react';
import CourseArticle from '../containers/course_article';
import {ICourseMaterial, ICourse} from '../interfaces/i_courses';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {apiGetSingleCourse, apiGetCourseMaterials, apiGetSingleMaterial} from '../services/apiCourses';
import {getSecondsUntilDate, toast} from '../utils';
import {observer} from 'mobx-react';
import ErrorData from '../components/ErrorData';
import {Header} from '../components/header';
import CourseArticleSmall from '../containers/course_article_small';
import {ICourseProgress} from '../interfaces/i_courses';
import OgTags from '../components/OgTags';
import GroupChatModal from '../containers/course_article/group_chat_modal';

interface MatchParams {
  page: string;
  id: string;
}

interface State {
  isSmallDevice?: boolean;
  courseStarted?: boolean;
  error: any,
  ready: boolean,
  preload: boolean,
  groupChatModal: boolean,
  progress: ICourseProgress;
  parent: ICourse;
  materials: ICourseMaterial[];
  selected?: ICourseMaterial;
}

@observer
class CourseArticlePage extends PureComponent<RouteComponentProps<MatchParams>> {
  _courseStartedTimer: any;

  state: State = {
    error: false,
    ready: false,
    preload: false,
    groupChatModal: false,
    parent: {},
    progress: {},
    materials: [],
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.match.params?.id !== this.props.match.params?.id) {
      this.get();
    }
  }

  componentWillUnmount() {
    this.clearStartedTimer();
    window.removeEventListener('resize', this.updateDimensions);
  }

  async componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
    const {location}: any = this.props;
    const {material} = location?.state || {};
    if (material) {
      this.setState({selected: material});
    }
    await this.get();
    this.checkSiderScroll();
  }

  get = async (withoutTimer?: boolean) => {
    const {match}: any = this.props;
    let stream_id = match.params?.id;
    try {
      let courseStarted = false;
      const data: any = await apiGetSingleCourse(stream_id);
      let timestamp = data?.time_to_start || 0;
      if (!withoutTimer && timestamp > 0) {
        this.startStartedTimer(timestamp);
      } else if (timestamp <= 0) {
        courseStarted = true;
      }
      await this.getMaterialsList(stream_id);
      this.setState({parent: data, ready: true, courseStarted}, () => this.checkChatModal(data));
    } catch (e: any) {
      toast(e.message);
      this.setState({error: e, ready: true});
    } finally {
    }
  }

  checkChatModal = (stream?: ICourse) => {
    if (stream && stream.id && !stream.show_modal && stream.chat_link) {
      this.setState({groupChatModal: true});
    }
  }
  checkSiderScroll = () => {
    const {history, location}: any = this.props;
    const material = location.state?.fromMaterial;
    let materialId = material?.id;
    if (!materialId && this.state.materials) {
      const itemToScroll = this.state.materials.slice(0).find(item => {
        return (item.type === 'start_date' && !item.hide) || (item.type === 'lesson' && !item.ready) || (item.type === 'task' && item.status !== 'accept')
      });
      materialId = itemToScroll?.id;
    }
    if (materialId) {
      const element: any = document.querySelector(`.sider-item div[data-id="${materialId}"]`);
      element?.scrollIntoView({
        block: 'center',
      });
      history.replace({state: {...location.state, fromMaterial: null}});
    }
  }
  updateDimensions = () => {
    const isSmallDevice = window.innerWidth <= 768;
    this.setState({isSmallDevice});
  };

  startStartedTimer = (timestamp: number) => {
    this.clearStartedTimer()
    if (timestamp > 0) {
      this._courseStartedTimer = setTimeout(() => {
        this.get(true);
      }, (timestamp * 1000 + 1000));
    }
  }
  clearStartedTimer = () => {
    clearTimeout(this._courseStartedTimer);
    this._courseStartedTimer = null;
  }

  setSelected = async (selected: ICourseMaterial) => {
    if (selected?.id !== this.state.selected?.id) {
      window.scrollTo(0, 0);
    }
    this.setState({selected, preload: selected?.type !== 'task'});
    if (!selected) return null;
    try {
      const res = await apiGetSingleMaterial(selected.stream_id, selected.id);
      this.setState({selected: res, preload: false});
      await this.getMaterialsList();
    } catch (e) {
      this.setState({selected: null, preload: false});
      throw e;
    }
  }

  getMaterialsList = async (id?: number | string) => {
    const {history, location}: any = this.props;
    history.replace({state: {...location.state, material: null}});
    try {
      const {materials, progress} = await apiGetCourseMaterials(id || this.state.parent?.id);
      this.setState({materials, progress});
    } catch (e) {
      throw e;
    } finally {
    }
  }

  updateMaterialData = async () => {
    const {selected}: any = this.state;
    try {
      const res = await apiGetSingleMaterial(selected.stream_id, selected.id);
      this.setState({selected: res, preload: false});
      await this.getMaterialsList();
    } catch (e) {
      throw e;
    }
  }

  handleNotifClick = async (item: any) => {
    if (this.state.selected?.id === item.id) {
      this.setState({preload: true});
    } else {
      await this.setSelected(item);
    }
  }

  render() {
    const {error, isSmallDevice} = this.state;
    if (error) return <>
      <Header className="position-relative" />
      <ErrorData error={this.state.error} className="mt-5" />
    </>
    if (isSmallDevice) return <>
      <CourseArticleSmall {...this.state}
                          getMaterialsList={this.get}
                          setSelected={this.setSelected}
                          updateMaterialData={this.updateMaterialData} />
      <GroupChatModal
        visible={this.state.groupChatModal}
        onClose={() => this.setState({groupChatModal: false})}
        link={this.state.parent?.chat_link}
        stream_id={this.state.parent?.stream_id}
      />
    </>
    return <>
      <OgTags data={this.state.parent} />
      <Header handleGoFromNotif={this.handleNotifClick} />
      <CourseArticle
        {...this.state}
        setSelected={this.setSelected}
        getMaterialsList={this.getMaterialsList}
        updateMaterialData={this.updateMaterialData}
      />
      <GroupChatModal
        visible={this.state.groupChatModal}
        onClose={() => this.setState({groupChatModal: false})}
        link={this.state.parent?.chat_link}
        stream_id={this.state.parent?.stream_id}
      />
    </>
  }
}


export default withRouter(CourseArticlePage);
