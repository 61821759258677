import {makeAutoObservable} from 'mobx';
import {AppStore} from './AppStore';
import {UserStore} from './UserStore';

export class RootStore {
  appStore: AppStore;
  userStore: UserStore;

  constructor() {
    this.appStore = new AppStore(this);
    this.userStore = new UserStore(this);
    makeAutoObservable(this);
  }
}

export default new RootStore();
