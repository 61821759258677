import React, {FC, memo, useCallback} from 'react';
import {ICourseArticleComponentProps, ICourseMaterial} from '../../../interfaces/i_courses';
import {SiderDate, SiderLessonTask, SiderSection} from '../sider_materials';

interface Props extends ICourseArticleComponentProps {
  material: ICourseMaterial;
}

const SiderMaterialsItem: FC<Props> = ({material, selected, setSelected, getMaterialsList}) => {
  const handleItemClick = useCallback((item: any) => {
    if(item.id !== selected?.id) {
      setSelected(item)
    }
  },[selected]);

  if (material.type === 'section') {
    return <SiderSection data={material} />
  }
  if (material.type === 'lesson' || material.type === 'task') {
    return <SiderLessonTask
      data={material}
      handleClick={handleItemClick}
      active={selected && material.id === selected.id && material.type === selected.type}
    />
  }
  if (material.type === 'timestamp' || material.type === 'start_date') {
    return <SiderDate date={material.data} start={material.type === 'start_date'} hide={material.hide} updateSider={getMaterialsList}/>
  }
  return null;
}


export default memo(SiderMaterialsItem);
