import React, {FC} from 'react';
import {ButtonHTMLAttributes} from 'react';
import Spinner from '../spinner';
import {observer} from 'mobx-react';
import {translate} from '../../utils';

interface Props extends ButtonHTMLAttributes<any> {
  type?: 'submit' | 'button';
  className?: string;
  title?: string;
  loading?: boolean;
}

const PrimaryBtn: FC<Props> = ({
                                 type,
                                 loading,
                                 className,
                                 children,
                                 title,
                                 ...props
                               }) => {
  return (
    <button
      className={`btn btn-primary${loading ? ' loading' : ''}${props.disabled ? ' disabled' : ''} ${className || ''}`}
      type={type || 'button'} {...props}>
      {loading ? <Spinner className="position-relative" small white /> : title ? <span>{translate(title)}</span> :children}
    </button>
  );
}


export default observer(PrimaryBtn);
