import React, {FC, useEffect} from 'react';
import {Redirect, Route, Switch, useHistory, useLocation} from 'react-router-dom';
import {
  AppHashPage,
  CoursesPage,
  NotFoundPage,
  CourseArticlePage, CourseMaterialPage, TaskChatPage
} from '../pages';
import {toast, translate, useQuery} from '../utils';

interface Props {
}

const AppRouting: FC<Props> = () => {
  const history = useHistory();
  const location = useLocation();
  let query = useQuery();

  useEffect(() => {
    const queryToken: any = query.get('token');
    const queryError: any = query.get('error');
    if (queryToken) {
      history.replace(location.pathname, location.state);
    } else if (queryError) {
      history.replace('/courses', location.state);
      toast(translate('payment_error'));
    }
  }, []);
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/courses"/>
      </Route>
      <Route exact path="/courses" render={() => <CoursesPage/>}/>
      <Route exact path="/privacy" render={() => <CoursesPage/>}/>
      <Route exact path="/courses/:id" render={() => <CourseArticlePage/>}/>
      <Route exact path="/material/:stream_id/:id" render={() => <CourseMaterialPage/>}/>
      <Route exact path="/invitation" render={() => <AppHashPage/>}/>
      <Route exact path="/recovery-password" render={() => <AppHashPage/>}/>
      <Route exact path="/task-chat/:stream_id/:id" render={() => <TaskChatPage/>}/>
      {/*<Route exact path="/webinar" render={() => <WebinarPage />} />*/}
      <Route render={() => <NotFoundPage/>}/>
    </Switch>
  );
}


export default AppRouting;
