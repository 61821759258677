import {api} from '../utils';
import {IPartialChunkUploadRequest, IInitPartialUploadRequest} from '../interfaces/i_upload';
import {IFile} from '../interfaces/i_app';

export const apiUploadBeginPartial = async (request: IInitPartialUploadRequest) => {
  try {
    return await api('/upload/begin', request, 'post');
  } catch (e: any) {
  }
};

export const apiUploadChunkPartial = async (request: IPartialChunkUploadRequest) => {
  try {
    return await api('/upload/chunk', request, 'post');
  } catch (e: any) {
  }
};
export const apiUploadBase64 = async (data: any, name: string): Promise<IFile[]> => {
  try {
    const res = await api('/upload/base64', {file: {data, name}}, 'post');
    return res.data;
  } catch (e: any) {
    throw e;
  }
};
