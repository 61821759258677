import React, {FC} from 'react';
import './material_type.css'
import RenderSvg from '../RenderSvg';
import check from '../../assets/icons/check.svg';
import {TaskStatus} from '../task';
import {ICourseMaterial} from '../../interfaces/i_courses';
import {translate} from '../../utils';
import {observer} from 'mobx-react';

interface Props {
  material?: ICourseMaterial
}

const MaterialType: FC<Props> = ({material}) => {
  if (!material) return null;
  if (material.type === 'lesson') {
    return (
      <div className="material-type">
        <span className="material-type-title">{translate(material.type)}</span>
        {material.ready ? <div className="mx-1 material-type-icon"><RenderSvg path={check} /></div> : null}
      </div>
    )
  }
  if (material.type === 'task') {
    return <div className="material-type">
      <span className="material-type-title">{translate(material.type)}</span>
      <TaskStatus small status={material.status} className="mx-1" />
    </div>
  }

  return null;
}


export default observer(MaterialType);
