import React, {FC} from 'react';
import {translate} from '../../utils';
import {observer} from 'mobx-react';
import RootStore from '../../store/RootStore';
import langs_json from '../../jsons/langs.json';
import RenderSvg from '../../components/RenderSvg';
import chevron from '../../assets/icons/chevron.svg';

interface Props {

}

const ProfileLangSelect: FC<Props> = () => {
  const {ln, langs, changeLang} = RootStore.appStore;
  const handleChange = (e: any) => {
    const ln = e.target.value;
    changeLang(ln);
  }
  return (
    <>
      <div className="input-label">{translate('INTERFACE_LANG')}</div>
      <div className="select-wrap">
        <select name="lang" value={ln || ''} onChange={handleChange}>
          {langs?.map(lang => (
            //@ts-ignore
            <option key={lang.lang} value={lang.lang}>{langs_json[lang.lang]?.native}</option>
          ))}
        </select>
        <div className="select-wrap-icon">
          <RenderSvg path={chevron} rotate={90} />
        </div>
      </div>
    </>
  );
}


export default observer(ProfileLangSelect);
