import React, {FC, useState} from 'react';
import LoginForm from './AuthForm';
import LoginIcon from './LoginIcon';
import {translate} from '../../utils';
import {Link} from 'react-router-dom';
import {observer} from 'mobx-react';
import {apiSendEmailToRecover} from '../../services/apiUser';


interface Props {

}

const Recovery: FC<Props> = () => {
  const [success, setSuccess] = useState(false);
  const onSubmit = async (e: any) => {
    try {
      await apiSendEmailToRecover(e.email);
      setSuccess(true);
    } catch (e: any) {
      throw e;
    }
  };

  return (
    <>
      <LoginIcon screen={success ? 'success_email' : 'recovery'} />
      {success
        ?
        <div className="auth-form">
          <div className="auth-title success">
            <h3 className="mb-0">{translate('EMAIL_SENDED')}</h3>
          </div>
          <Link to={'/'} className="btn mt-3 p-0">{translate('Back')}</Link>
        </div>
        :
        <LoginForm
          title={'RECOVERY_PASSWORD'}
          description="AUTH_RECOVERY_EMAIL_DESCRIPTION"
          btnTitle={'SEND'}
          onSubmit={onSubmit}
          fields={['email']}
          onCancel
        />
      }
    </>
  );
}

export default observer(Recovery);
