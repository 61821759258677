import React, {FC} from 'react';
import icon from '../assets/icons/applications/error.svg';
import {translate} from '../utils';
import SVG from 'react-inlinesvg';
import {observer} from 'mobx-react';

interface Props {
  title?: string;
  error?: any;
  className?: string;
}

const ErrorData: FC<Props> = ({title, className}) => {
  // const history = useHistory();
  // const handleRefresh = () => {
  //
  // }
  return (
    <div className={`text-center mt-4 ${className || ''}`}>
      <SVG src={icon} />
      <div className="muted pt-4">{translate(title || 'TRY_RELOAD_PAGE')}</div>
      {/*<PrimaryBtn title='Refresh' onClick={handleRefresh} className='mt-4'/>*/}
    </div>
  );
}


export default observer(ErrorData);
