import React, {FC} from 'react';
import './file.css'
import {IUpload} from '../../interfaces/i_upload';
import RenderSvg from '../RenderSvg';
import file_svg from '../../assets/icons/file.svg';
import {addHostToPath, translate} from '../../utils';
import {observer} from 'mobx-react';

const getFileSize = (size: number): string => {
  if (!size) return ''
  const newSize = size / 1000;
  return `${newSize} ${translate('KB')}`;
}

interface Props {
  file?: IUpload;
  className?: string;
}

const File: FC<Props> = ({file, className}) => {
  return (
    <div className="file-wrap">
      <a href={addHostToPath(file)} download={file?.info?.filename || file?.uuid} type="application/octet-stream"
         rel="noopener noreferrer" target="_blank" className={`file ${className || ''}`}>
        <div className="file-icon">
          <RenderSvg path={file_svg} />
        </div>
        <div className="file-info">
          <div className="text-12 file-title text-truncate" dir={'auto'}>{file?.info?.filename || translate('NO_NAME')}</div>
          {file?.info?.filesize ? <div className="muted-12">{getFileSize(file.info.filesize || 0)}</div> : null}
        </div>
      </a>
    </div>
  );
}


export default observer(File);
