import React, {FC} from 'react';
import './spinner.css'

interface Props {
  visible?: boolean;
  white?: boolean;
  small?: boolean;
  className?: string;
  style?: any;
}

const Spinner: FC<Props> = ({visible = true, className, style, white, small}) => {
  if (!visible) return null;
  return (
    <div className={`spinner ${className || ''}${small ? ' small' : ''}`} style={style}>
      <div className={`loader${white ? ' white' : ''}`} />
    </div>
  );
}

export default Spinner;
