import React, {PureComponent} from 'react';
import './timer.css'
import {observer} from 'mobx-react';
import TimerItem from './TimerItem';
import {getSecondsUntilDate, translate} from '../../utils';

interface Props {
  className?: string;
  title?: string;
  startDate: any;
  afterTimerEnded?: () => void;
}

@observer
class Timer extends PureComponent<Props> {
  _timer: any;

  state = {
    time: {days: 0, hours: 0, minutes: 0, seconds: 0},
    needShow: false,
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.startDate !== this.props.startDate) {
      this.startTimer(true);
    }
  }

  componentDidMount() {
    this.startTimer(true);
  }

  clearTimer = () => {
    clearInterval(this._timer);
    this._timer = null;
  }

  startTimer = (init?: boolean) => {
    const {startDate, afterTimerEnded} = this.props;
    if (!startDate) {
      return this.clearTimer();
    }
    let timestamp = getSecondsUntilDate(startDate);
    if (timestamp <= 0) {
      timestamp = 0;
      this.clearTimer();
      if (afterTimerEnded && !init) {
        setTimeout(afterTimerEnded,1001);
      }
      this.setState({needShow: false});
    } else if (init) {
      this.setState({needShow: true});
      this._timer = setInterval(this.startTimer, 1000);
    }
    let days = Math.floor(timestamp / (3600 * 24));
    let hours = Math.floor(timestamp % (3600 * 24) / 3600);
    let minutes = Math.floor(timestamp % 3600 / 60);
    let seconds = timestamp % 60;
    this.setState({time: {days, hours, minutes, seconds}});
  }

  render() {
    const {className, title} = this.props;
    const {days, hours, minutes, seconds} = this.state.time;
    if (!this.state.needShow) return null;
    return (
      <div className={`timer ${className || ''}`}>
        {title ? <div className="timer-title">{translate(title)}</div> : null}
        <div className="timer-board">
          <TimerItem text={'Days'} time={days} disabled={!days} />
          <TimerItem text={'Hours'} time={hours} disabled={!hours && !days} />
          <TimerItem text={'Minutes'} time={minutes} disabled={!minutes && !hours && !days} />
          <TimerItem text={'Seconds'} time={seconds} disabled={!minutes && !seconds && !hours && !days} />
        </div>
      </div>
    );
  }
}


export default Timer;
