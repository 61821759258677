import React from 'react';
import icon from '../assets/icons/applications/404.svg';
import {Link} from 'react-router-dom';
import SVG from 'react-inlinesvg';
import {observer} from 'mobx-react';
import {translate} from '../utils';
import {Header} from '../components/header';

const NotFoundPage = () => {
  return (
    <>
      <Header />
      <div className="page-404">
        <SVG src={icon} />
        <h4>{translate('page_404')}</h4>
        <Link to="/" className="btn btn-outline">{translate('on_main')}</Link>
      </div>
    </>
  );
};


export default observer(NotFoundPage);
