import React, {FC} from 'react';
import {Link} from 'react-router-dom'
import './breadcrumbs.css'
import {translate} from '../../utils';
import {observer} from 'mobx-react';

type TBreadcrumbs = { name?: string, path?: string, params?: any, onClick?: () => void };

interface Props {
  className?: string;
  data?: TBreadcrumbs[];
}

const Breadcrumbs: FC<Props> = ({data, className}) => {
  if (!data?.length) return null;
  return (
    <div className={`breadcrumbs ${className || ''}`}>
      {data.map((item, i) => (
        <div className="breadcrumbs-item" key={i} dir={'auto'}>
          {item?.path
            ?
            <Link to={{
              pathname: item?.path || '/',
              state: item.params || {}
            }} className={`breadcrumbs-link`}>{translate(item.name)}</Link>
            :
            <div className={`breadcrumbs-link`} onClick={item.onClick}>{translate(item.name)}</div>
          }
          <div className="breadcrumbs-item-arrow" />
        </div>
      ))}
    </div>
  );
}


export default observer(Breadcrumbs);
