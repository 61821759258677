import React, {FC, useEffect, useState} from 'react';
import {toast, translate} from "../../../utils";
import {apiCreateOrder} from "../../../services/apiCourses";
import Spinner from "../../../components/spinner";
import {EPaymentTypes, IOrder} from "../../../interfaces/i_app";
import {ICourse} from "../../../interfaces/i_courses";
import RootStore from "../../../store/RootStore";
import {observer} from "mobx-react";
import paypal_img from '../../../assets/images/paypal.png';
import stripe_img from '../../../assets/images/stripe.png';

interface Props {
  order?: IOrder;
  stream: ICourse;
  setOrder: (order: IOrder) => void;
  paymentType: EPaymentTypes.paypal | EPaymentTypes.stripe
}

const paymentData: any = {
  paypal: {logo:paypal_img, title: 'PAY_PAYPAL'},
  stripe: {logo:stripe_img, title: 'PAY_STRIPE' },
}

const PaypalCard: FC<Props> = ({order, stream, setOrder, paymentType}) => {
  const [ready, setReady] = useState(false);
  const [error, setError] = useState<any>(false);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    try {
      const {user} = RootStore.userStore;
      const res = await apiCreateOrder({
        email: user.email!,
        stream_id: stream.id!,
        paymentType,
        location: window.location.origin || window.location.href,
      });
      const {redirect_url, merchant_payment_link} = res || {};
      if (redirect_url) {
        window.location.href = redirect_url;
      } else if (merchant_payment_link) {
        setOrder(res);
      } else {
        toast('Payment_error');
        setError({message: 'Payment_error'});
      }
    } catch (e: any) {
      setError(e)
    } finally {
      setReady(true)
    }
  }

  if (!ready) return <div className='payment-modal-card'><Spinner/></div>
  if (error) return <div className='payment-modal-card'>{error?.message}</div>
  return (
    <div className='payment-modal-card paypal' style={paymentData[paymentType]?.style}>
      <img src={paymentData[paymentType]?.logo} alt="logo" className='paypal-img'/>
      <div className='pb-3 pt-2 mt-1 medium'>{translate(paymentData[paymentType]?.title)}</div>
      <a href={order?.merchant_payment_link} target='_blank' className='btn btn-primary mt-3'>
        {translate('PAY_NOW')}
      </a>
    </div>
  );
}


export default observer(PaypalCard);