import React, {FC} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {ErrorBoundary} from '../containers';
import AppRouting from './AppRouting';
import {ToastContainer} from 'react-toastify';
import {CloseBtn} from '../components/buttons';

const AppNavigation: FC = () => {
  return (
    <Router>
      <ErrorBoundary>
        <AppRouting />
      </ErrorBoundary>
      <ToastContainer closeButton={<CloseBtn />} />
    </Router>
  );
}


export default AppNavigation;
