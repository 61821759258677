import React, {FC, useEffect} from 'react';
import {observer} from 'mobx-react';
import {translate} from '../../utils';
import RenderSvg from '../RenderSvg';
import chevron from '../../assets/icons/chevron.svg';
import OutsideClick from '../OutsideClick';

interface Props {
  visible: string;
  outsideClick: (e?: any) => void;
  title?: string;
}

const RightHiddenWrapper: FC<Props> = ({outsideClick, visible, children}) => {
  // useEffect(() => {
  //   const onScroll = () => {
  //     if(visible) {
  //       outsideClick();
  //     }
  //   };
  //   window.removeEventListener('scroll', onScroll);
  //   window.addEventListener('scroll', onScroll, { passive: true });
  //   return () => window.removeEventListener('scroll', onScroll);
  // }, [visible]);

  const handleOutsideClick = (e: any) => {
    e.stopPropagation();
    outsideClick();
  }
  return (
    <OutsideClick outsideClickEvent={handleOutsideClick}  className={`header-right-hidden${visible ? ' show' : ''}`}>
      <div className="header-right-hidden-top">
        <button type="button" className="btn-circle" onClick={outsideClick}><RenderSvg path={chevron} /></button>
        <h5 className="title">{translate(visible)}</h5>
      </div>
      <div className="header-right-hidden-content">
        {children}
      </div>
    </OutsideClick>
  );
}


export default observer(RightHiddenWrapper);
