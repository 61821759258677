import React, {FC, useState} from 'react';
import {observer} from 'mobx-react';
import {MutedBtn, PrimaryBtn} from '../../components/buttons';
import {translate} from '../../utils';
import RenderSvg from '../../components/RenderSvg';
import check from '../../assets/icons/check.svg'
import {useHistory} from 'react-router-dom';


interface Props {
  passed?: boolean;
  onClick: (unread?: number) => void;
}

const ContentPassedBtns: FC<Props> = ({passed, onClick}) => {
  const [loading, setLoading] = useState(false);
  const handleClick = (unread?: number) => async () => {
    try {
      setLoading(true);
      await onClick(unread);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }
  return (
    <div className="content-passed-btns">
      {!passed
        ?
        <PrimaryBtn onClick={handleClick(0)} loading={loading}>{translate('MARK_LEARNED')}</PrimaryBtn>
        :
        <MutedBtn onClick={handleClick(1)} loading={loading}>
            <RenderSvg path={check} size={24} />
          <span>{translate('Learned')}</span>
        </MutedBtn>
      }
    </div>
  );
}


export default observer(ContentPassedBtns);
