import React, {FC} from 'react';
import {INotificationData, TNotificationType} from '../../../interfaces/i_notifications';
import {translate} from '../../../utils';

interface Props {
  type?: TNotificationType;
  data?: INotificationData;
}

const NotificationMessage: FC<Props> = ({type, data}) => {
  let messagePrefix = '';
  if (data?.minute_before_start) {
    messagePrefix = '_' + data.minute_before_start;
  }
  return (
    <div className="notifications-message"
         dangerouslySetInnerHTML={{__html: translate(`notif_${type}${messagePrefix}`, 'change', data?.name || data?.curator_name || '')}} />
  );
}

export default NotificationMessage;
