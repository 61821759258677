import {action, makeAutoObservable, observable, toJS} from 'mobx';
import {RootStore} from './RootStore';
import {ITranslates} from '../interfaces/i_app';
import {ITranslatesLang} from '../interfaces/i_translates';
import {apiGetLanguages, apiGetTranslates} from '../services/apiApp';
import {setRtlLang} from '../utils';

export class AppStore {
  rootStore: RootStore;

  translations: ITranslates = {};
  langs: ITranslatesLang[] = [];
  ln: string = 'en';

  ready: boolean = false;
  isAuth: boolean = false;


  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {
      translations: observable,
      langs: observable,
      ln: observable,
      ready: observable,
      isAuth: observable,


      init: action,
      setState: action,
      getActiveLangs: action,
      getTranslations: action,
      changeLang: action,
    });
  }

  init = async () => {
    try {
      await this.getActiveLangs();
      await this.getTranslations()
      await this.rootStore.userStore.getUser()
      this.isAuth = true;
    } catch (e) {
    } finally {
      this.ready = true;
    }
  }

  setState = (object: object): void => {
    for (let key in object) {
      //@ts-ignore
      this[key] = toJS(object[key]);
    }
  };

  getActiveLangs = async () => {
    this.langs = await apiGetLanguages();
  };

  getTranslations = async (ln?: string) => {
    if (!ln) {
      ln = localStorage.ln || navigator.language || 'en';
    }
    const isLang = this.langs?.findIndex(item => item.lang === ln);
    if (isLang === -1) {
      ln = 'en';
    }
    ln = ln || 'en'
    setRtlLang(ln);
    this.ln = ln;
    this.translations = await apiGetTranslates(ln);
  };

  changeLang = async (ln: string) => {
    this.ln = ln;
    localStorage.setItem('ln', ln);
    await this.getTranslations(ln);
  };
}
