import React, {FC, useEffect, useState} from 'react';
import {ICourseMaterial} from '../../interfaces/i_courses';
import {observer} from 'mobx-react';
import ContentBody from '../../components/content_body';
import {TaskAnswers, TaskStatus} from '../../components/task';
import {apiGetTaskChat} from '../../services/apiCourses';
import CourseMaterialWrapper from './CourseMaterialWrapper';
import {ITaskAnswer} from '../../interfaces/i_tasks';
import NextMaterial from './NextMaterial';
import {PrimaryBtn} from '../../components/buttons';
import LoadingWrapper from '../../components/loading_wrapper';
import {useHistory} from 'react-router-dom';

export const scrollToBottomChat = (every?: boolean) => {
  setTimeout(() => {
    const objDiv: any = document.getElementsByClassName('task-content')?.[0];
    if (!objDiv) return null;
    const scrollTop = objDiv.pageYOffset || objDiv.scrollTop
    if (every || objDiv.scrollHeight - objDiv.clientHeight === scrollTop) {
      objDiv?.scroll({top: 999999, behavior: 'auto'});
    }
  }, 150);
}

interface Props {
  preload?: boolean;
  data: ICourseMaterial;
  updateData?: () => void;
  setSelected: (material: any) => void;
}

const ContentCourseLesson: FC<Props> = ({preload, data, updateData, setSelected}) => {
  const history: any = useHistory();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>(false);
  const [chatVisible, setChatVisible] = useState(false);
  const [answers, setAnswers] = useState<ITaskAnswer[]>([]);

  useEffect(() => {
    if(preload) {
      console.log(123);
      handleUpdateMaterial();
    }
  }, [preload]);

  useEffect(() => {
    scrollToBottomChat();
  }, [answers]);

  useEffect(() => {
    if (data) {
      setLoading(true);
      setChatVisible(false);
      setError(false);
      init();
      const _interval = setInterval(handleUpdateMaterial, 30000);
      return () => {
        clearInterval(_interval);
      }
    }
  }, [data?.id]);

  const init = async () => {
    const resAnswers = await getTaskAnswers();
    if (resAnswers?.length) {
      setChatVisible(true);
    } else {
      setChatVisible(false);
    }
    setLoading(false);
  }

  const getTaskAnswers = async () => {
    try {
      const res = await apiGetTaskChat(data.id);
      setAnswers(res);
      scrollToBottomChat(true);
      return res;
    } catch (e) {
    }
  }

  const handleUpdateMaterial = async (needScroll?: boolean) => {
    if (updateData) updateData();
    await getTaskAnswers();
    if (needScroll) {
      scrollToBottomChat(true);
    }
  }

  const handleLookChatClick = () => {
    const isSmallDevice = window.innerWidth <= 768;
    if (isSmallDevice) {
      history.push(`/task-chat/${data.stream_id}/${data.id}/`)
    } else {
      setChatVisible(true);
    }
  }

  const handleNextClick = (item: any) => {
    setSelected(item)
  }

  return (
    <LoadingWrapper>
      <CourseMaterialWrapper
        className={!chatVisible ? 'course-type-task task-no-chat' : 'course-type-task'}
        loading={loading}
        error={error}
        number={data?.number}
        material={data}
        nextComponent={() => <NextMaterial number={data?.number}
                                           material={data?.next}
                                           updateData={updateData}
                                           onClick={handleNextClick} />}
        leftContent={() => <>
          <ContentBody data={data?.data} />
          <TaskStatus status={data?.status} className="mt-4" mediumStyle />
          <PrimaryBtn title="Answer" className="look-chat-btn" onClick={handleLookChatClick} />
        </>}
        rightContent={() => <TaskAnswers animated answers={answers} task={data}
                                         updateList={() => handleUpdateMaterial(true)} />}
      />
    </LoadingWrapper>
  );
}


export default observer(ContentCourseLesson);
