import {api} from '../utils';
import {IPagination} from '../interfaces/i_app';
import {TMethods} from '../utils/api';

export const apiGetListData = async <T>(route: string, data?: any, method?: TMethods): Promise<{data: T[], meta: {pagination: IPagination}}> => {
  try {
    return await api(route, data || {}, method || 'get');
  } catch (e: any) {
    throw e;
  }
};

export const apiGetSingleData = async <T>(route: string, data: any): Promise<T> => {
  try {
    const res = await api(route, data, 'get');
    return res.data;
  } catch (e: any) {
    throw e;
  }
};

export const apiCreateDataByName = async <T>(route: string, name: string): Promise<T> => {
  try {
    const res = await api(route, {name}, 'post');
    return res.data;
  } catch (e: any) {
    throw e;
  }
};

export const apiRemoveData = async (route: string, id?: number | string): Promise<void> => {
  try {
    await api(route, id ? {id} : {}, 'delete');
  } catch (e: any) {
    throw e;
  }
};

export const apiPostData = async <T>(route: string, data: T): Promise<T> => {
  try {
    const res = await api(route, data, 'post');
    return res.data;
  } catch (e: any) {
    throw e;
  }
};

export const apiUpdateData = async <T>(route: string, data: T): Promise<T> => {
  try {
    const res = await api(route, data, 'put');
    return res.data;
  } catch (e: any) {
    throw e;
  }
};
