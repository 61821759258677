import React, {FC} from 'react';
import {ICourse} from '../../interfaces/i_courses';
import TextTruncate from 'react-text-truncate';
import ChatLink from '../../components/chat_link';
import {translate} from '../../utils';
import {observer} from 'mobx-react';
import BigStar from '../../components/BigStar';

interface Props {
  course: ICourse;
  handleDetails: () => void;
}

const CourseArticleSmallHeader: FC<Props> = ({course, handleDetails}) => {
  if (!course) return null;
  return (
    <div className="course-article__small-content-header">
      <div className="text-12">{course.stream_name}</div>
      <TextTruncate
        line={2}
        element="h4"
        containerClassName="title"
        truncateText="…"
        text={course?.name}
      />
      <BigStar index={course.id || 0} animated />
      <ChatLink small text className={'mt-3'} link={course.chat_link} />
      <button className="btn btn-outline" type={'button'} onClick={handleDetails}>{translate('DETAIL')}</button>
      <div className="bold-14 pb-2">{translate('Material')}</div>
      {/*<Progress full={32} progress={12} />*/}
    </div>
  );
}


export default observer(CourseArticleSmallHeader);
