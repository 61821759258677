import React, {FC} from 'react';
import {INotification} from '../../../interfaces/i_notifications';
import RenderSvg from '../../RenderSvg';
import trash from '../../../assets/icons/trash.svg';
import moment from 'moment';
import {translate} from '../../../utils';
import NotificationMessage from './NotificationMessage';
import rootStore from '../../../store/RootStore';
import {observer} from 'mobx-react';
import {TaskStatus} from '../../task';
import NotificationIcon from './NotificationIcon';

interface Props {
  notification: INotification;
  handleClick: (notification: INotification) => void;
  handleRemove: (notif?: number | 'removeAll') => void;
}

const NotificationListItem: FC<Props> = ({notification, handleClick, handleRemove}) => {
  return (
    <div className={`notifications-list-item${notification?.read ? ' read' : ''}`}>
      <NotificationIcon notification={notification} />
      <div className="flex-grow-1 position-relative pt-2">
        <NotificationMessage {...notification} />
        {notification.type === 'task' ? <TaskStatus className='pt-1' mediumStyle status={notification.data?.status} /> : null}
        <div className="notifications-date">
          {notification.created_at ? moment(notification.created_at).locale(rootStore.appStore.ln).format('DD MMM YYYY HH:mm') : null}
        </div>
        {notification.entity_id
          ?
          <button type="button" className="btn btn-muted" onClick={() => handleClick(notification)}>
            {translate('GO_TO')}
          </button>
          :
          null
        }
      </div>
      <button className="btn-circle btn-circle-small notifications-item-trash" type={'button'}
              onClick={() => handleRemove(notification?.id)}>
        <RenderSvg path={trash} />
      </button>
    </div>
  );
}


export default observer(NotificationListItem);
