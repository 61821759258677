import React, {FC, useEffect, useRef} from 'react';
import './course_sider.css'
import {ICourseArticleComponentProps} from '../../../interfaces/i_courses';
import CourseSiderHeader from './CourseSiderHeader';
import SiderMaterials from './SiderMaterials';


export function toggleHeaderByScroll(el: string, distance?: number): any {
  let loadingHeaderTransition = false;
  let prevScrollPos = 0;
  return function scrolling(e: any, disable?: boolean) {
    if (disable) return null;
    let scrollDistance = e.target.scrollTop;
    const headerEl: any = document.querySelector(el);
    if (loadingHeaderTransition) return null;
    if (scrollDistance > (distance || 100)) {
      if (!headerEl.classList.contains('small-container')) {
        setTimeout(() => loadingHeaderTransition = false, 500);
        headerEl.classList.add('small-container');
        loadingHeaderTransition = true;
      }
    } else if (headerEl.classList.contains('small-container') && scrollDistance < prevScrollPos) {
      headerEl.classList.remove('small-container');
    }
    prevScrollPos = scrollDistance;
  }
}

interface Props extends ICourseArticleComponentProps {

}

const CourseSider: FC<Props> = (props) => {
  let handleScroll = useRef(toggleHeaderByScroll('.course-sider', 1))?.current;

  return (
    <div className="course-sider">
      <CourseSiderHeader  {...props} />
      <SiderMaterials handleScroll={handleScroll} {...props} />
    </div>
  );
}

export default CourseSider;
