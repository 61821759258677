import React, {FC, useEffect, useRef, useState} from 'react';
import {toast} from "../../../utils";
import {apiCreateOrder, apiGetSingleCourse} from "../../../services/apiCourses";
import Spinner from "../../../components/spinner";
import {EPaymentTypes, IOrder} from "../../../interfaces/i_app";
import {ICourse} from "../../../interfaces/i_courses";
import RootStore from "../../../store/RootStore";
import {observer} from "mobx-react";
import {useHistory} from "react-router-dom";

interface Props {
  order?: IOrder;
  stream: ICourse;
  setOrder: (order: IOrder) => void;
}

const PaykassamaCard: FC<Props> = ({order, stream, setOrder}) => {
  const history: any = useHistory();

  const payment_status_ping: any = useRef(null);
  const [ready, setReady] = useState(false);
  const [error, setError] = useState<any>(false);

  useEffect(() => {
    init();
    payment_status_ping.current = setInterval(ping, 3000);
    return () => {
      clearInterval(payment_status_ping.current);
    }
  }, []);

  const ping = async () => {
    try {
      await apiGetSingleCourse(stream.id);
      history.push(`/courses/${stream.id}`, {course: stream});
    } catch (e) {

    }
  }

  const init = async () => {
    try {
      const {user} = RootStore.userStore;
      const res = await apiCreateOrder({
        email: user.email!,
        stream_id: stream.id!,
        paymentType: EPaymentTypes.paykassma,
        location: window.location.origin || window.location.href,
      });
      const {redirect_url, order_id} = res || {};
      if (redirect_url) {
        window.location.href = redirect_url;
      } else if (order_id) {
        setOrder(res);
      } else {
        toast('Payment_error')
      }
    } catch (e: any) {
      setError(e)
    } finally {
      setReady(true)
    }

  }


  if (!ready) return <div className='payment-modal-card'><Spinner/></div>
  if (error) return <div className='payment-modal-card'>{error?.message}</div>
  return (
    <div className='payment-modal-card'>
      <iframe
        id="kassma-plugin-frame"
        src={`https://plugin.courses-pay.com/?label=${order?.order_id}&currency_code=${order?.currency}&fixed_amount=${order?.price}&custom_transaction_id=${order?.custom_transaction_id}`}
        width="100%"
        height="100%"
        frameBorder="0"
      />
    </div>
  );
}


export default observer(PaykassamaCard);