import React, {FC, useState} from 'react';
import './profile.css';
import {observer} from 'mobx-react';
import RootStore from '../../store/RootStore';
import Avatar from '../../components/avatar';
import ProfileForm from './ProfileForm';
import RenderSvg from '../../components/RenderSvg';
import {toast, translate} from '../../utils';
import edit from '../../assets/icons/edit.svg';
import {TermsLinks} from "../terms";

interface Props {
  setDisableOutsideClick?: (status: boolean) => void;
}

const Profile: FC<Props> = ({setDisableOutsideClick}) => {
  const [editForm, setEditForm] = useState(false);
  const {user, updateUser, logOut, updateUserObj} = RootStore.userStore || {};

  const toggleEdit = (status: boolean, e?: any) => {
    if (e) {
      e.stopPropagation()
    }
    setEditForm(status);
  }

  const handleUpdateAvatar = async (img: any) => {
    try {
      updateUserObj({avatar: img});
      await updateUser({avatar_file_uuid: img.uuid});
      toast('UPDATE_AVATAR_SUCCESS', 'success');
    } catch (e) {
      throw e;
    }
  }
  const handleUpdateProfile = async (profile: any) => {
    try {
      await updateUser(profile);
      toggleEdit(false);
      toast('CHANGES_SAVED', 'success');
    } catch (e) {
      throw e;
    }
  }

  return (
    <div className='profile-wrap'>
      <div className={`profile-content${editForm ? ' profile-edit' : ''}`}>
        <Avatar user={user} className="profile-avatar" handleEdit={editForm ? handleUpdateAvatar : undefined}/>
        <div className="w-100 position-relative">
          {!editForm
            ?
            <div className="profile-info  animate__fadeInRight fadeInRight animate__animated">
              <div className="medium-20">{user.name}</div>
              <div className="muted pt-1 pb-3">{user.email}</div>
              <button type="button" className="btn btn-outline" onClick={(e) => toggleEdit(true, e)}>
                <RenderSvg path={edit}/>
                <span className="px-2">{translate('Edit')}</span>
              </button>
              <button type="button" className="btn btn-transparent mt-2" onClick={logOut}>{translate('Logout')}</button>

            </div>
            :
            <ProfileForm
              user={user}
              visible={editForm}
              handleUpdateProfile={handleUpdateProfile}
              closeEdit={(e) => toggleEdit(false, e)}
            />
          }
        </div>
      </div>
      {!editForm && <TermsLinks setDisableOutsideClick={setDisableOutsideClick}/>}
    </div>
  );
}

export default observer(Profile);
