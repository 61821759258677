import React, {FC} from 'react';
import {observer} from 'mobx-react';
import RootStore from '../store/RootStore';
import {AuthPage} from '../pages';
import AppNavigation from './AppNavigation';

const Navigation: FC = () => {
  const {appStore} = RootStore;
  if (!appStore.isAuth) return <AuthPage />
  return <AppNavigation />
}


export default observer(Navigation);
