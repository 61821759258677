import React, {FC} from 'react';
import {translate} from '../../../utils';
import {observer} from 'mobx-react';
import {IPagination} from '../../../interfaces/i_app';

interface Props {
  meta?: IPagination;
  onClick: () => void;
}

const NotificationsShowMore: FC<Props> = ({meta, onClick}) => {
  if (meta && meta?.total_pages && meta?.current_page && meta.current_page < meta.total_pages) {
    return (
      <div className="notifications-show-more" onClick={onClick}>{translate('Show_more')}</div>
    )
  }
  return null
}

export default observer(NotificationsShowMore);
