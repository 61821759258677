import React, {FC, useCallback, useEffect, useState} from 'react';
import './notifications.css'
import RightHiddenWrapper from '../RightHiddenWrapper';
import RenderSvg from '../../RenderSvg';
import bell from '../../../assets/icons/bell.svg';
import NotificationList from './NotificationList';
import {INotification} from '../../../interfaces/i_notifications';
import {IPagination} from '../../../interfaces/i_app';
import {apiGetListData, apiGetSingleData, apiPostData, apiRemoveData} from '../../../services/apiData';
import Profile from '../../../containers/profile';

interface Props {
  handleShowHidden: (type: string) => void;
  visible: string;
  handleGoFromNotif?: (material: any) => void;
}

const Notifications: FC<Props> = ({visible, handleShowHidden, handleGoFromNotif}) => {
  const [notifBadge, setNotifBadge] = useState(0);
  const [notifLoading, setNotifLoading] = useState(false);
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [notifMeta, setNotifMeta] = useState<IPagination>({});
  const [disableOutsideClick, setDisableOutsideClick] = useState (false);

  useEffect(() => {
    getNotifBadge();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getNotifBadge();
    }, 30000);
    return () => clearTimeout(interval);
  }, []);


  const getListNotif = async (page?: number) => {
    setNotifLoading(true);
    try {
      const res: any = await apiGetListData('/notification', {page: page || 1, limit: 15});
      const {items, meta} = res?.data?.notification || {};
      if (page && page > 1) {
        setNotifications([...notifications, ...items]);
      } else {
        setNotifications(items);
      }
      setNotifMeta(meta);
    } catch (e) {
    } finally {
      setNotifLoading(false);
    }
    await getNotifBadge();
  }

  const getNotifBadge = async () => {
    try {
      const res: any = await apiGetSingleData('/notification/count', {});
      setNotifBadge(res?.notification_count?.items?.count);
    } catch (e) {
    }
  }
  const readAllNotif = useCallback(async () => {
    try {
      await apiPostData('/notification/readAll', {});
      getNotifBadge();
    } catch (e) {
    }
  }, []);

  const removeNotif = useCallback(async (notif?: number | 'removeAll') => {
    try {
      setNotifLoading(true);
      await apiRemoveData(`/notification/${notif}`);
      if (notif === 'removeAll') {
        setNotifications([]);
        setNotifMeta({});
      } else {
        setNotifications((prevState) => {
          return prevState.filter(item => item.id !== notif);
        })
      }
    } catch (e) {
    } finally {
      setNotifLoading(false);
    }
  }, []);

  const outsideClick = (e?: any) => {
    e?.stopPropagation();
    if(disableOutsideClick) return null;
    handleShowHidden('');
  }

  const handleClick = (e: any) => {
    e.stopPropagation();
    getListNotif(1);
    handleShowHidden('notifications');
  }

  return (
    <>
      <div className={`notification`} onClick={handleClick}>
        <RenderSvg path={bell} />
        {notifBadge
          ?
          <div className="notification-badge-wrap">
            <div className="notification-badge">
              {notifBadge}
            </div>
          </div>
          :
          null
        }
      </div>
      <RightHiddenWrapper visible={visible} outsideClick={outsideClick}>
        {visible === 'notifications' ?
          <NotificationList notifications={notifications}
                            meta={notifMeta}
                            notifBadge={notifBadge}
                            close={outsideClick}
                            get={getListNotif}
                            handleGoFromNotif={handleGoFromNotif}
                            readAllNotif={readAllNotif}
                            loading={notifLoading}
                            removeNotif={removeNotif} />
          : null
        }
        {visible === 'profile' ? <Profile setDisableOutsideClick={setDisableOutsideClick} /> : null}
      </RightHiddenWrapper>
    </>
  );
}

export default Notifications;
