import React, {FC, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import LoginForm from './AuthForm';
import RootStore from '../../store/RootStore';
import LoginIcon from './LoginIcon';
import Spinner from '../../components/spinner';
import {observer} from 'mobx-react';
import {apiCheckHash} from '../../services/apiUser';
import {useQuery} from '../../utils';


interface Props {
  title: string;
  description: string;
}

const RecoveryPassword: FC<Props> = ({title, description}) => {
  const [loading, setLoading] = useState(true);
  const [hash, setHash] = useState('');
  let query = useQuery();
  const history = useHistory();

  useEffect(() => {
    const queryHash: any = query.get('hash');
    if (!queryHash) {
      return history.replace('/');
    }
    setHash(queryHash);
    init();
  }, []);

  const init = async () => {
    try {
      await apiCheckHash(hash);
      setLoading(false);
    } catch (e) {
      history.replace('/');
    }
  }

  const onSubmit = async (e: any) => {
    if (!e.password) return null;
    try {
      await RootStore.userStore.loginByHash({hash, password: e.password});
      history.replace('/courses');
      RootStore.appStore.setState({isAuth: true});
    } catch (e) {
    } finally {
    }
  };

  if (loading) return  <Spinner  visible={loading} className='position-relative' />;
  return (
    <>
      <LoginIcon screen={'recovery'} />
      <LoginForm
        title={title}
        description={description}
        btnTitle={'Done'}
        onSubmit={onSubmit}
        fields={['password']}
        onCancel
      />
    </>
  );
}

export default observer(RecoveryPassword);
