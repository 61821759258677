import React, {FC, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import Spinner from '../components/spinner';
import RootStore from '../store/RootStore';
import {apiCheckHash} from '../services/apiUser';
import {useQuery} from '../utils';

interface Props {

}

const AppHashPage: FC<Props> = () => {
  let query = useQuery();
  const history = useHistory();

  useEffect(() => {
    const hash: any = query.get('hash');
    if (!hash) {
      return history.replace('/');
    }
    init(hash);
  }, []);

  const init = async (hash: string) => {
    try {
      await apiCheckHash(hash);
      await RootStore.userStore.logOut();
    } catch (e) {
      history.replace('/');
    }
  }

  return (
    <div className="h-100 d-flex align-items-center">
      <Spinner style={{top: 'auto'}} />
    </div>
  );
}

export default AppHashPage;
