import React, {FC} from 'react';
import DateInfo from "../../../components/date_info";
import {ICourse} from "../../../interfaces/i_courses";
import {observer} from "mobx-react";
import {translate} from "../../../utils";
import SVG from "react-inlinesvg";
import star_bg from '../../../assets/icons/star_bg.svg';
import chevron from '../../../assets/icons/chevron.svg';
import head from '../../../assets/images/head.png';
import RenderSvg from "../../../components/RenderSvg";

interface Props {
  stream: ICourse;
  price: string|number;
  currency: string;
  toggleVisible: (stream?: ICourse) => void;
  className?: string;
  own?: boolean;
  visible: boolean;
}

const HyperpayStreamInfoListItem: FC<Props> = ({
                                                 className,
                                                 stream,
                                                 price,
                                                 currency,
                                                 visible,
                                                 own,
                                                 toggleVisible,
                                               }) => {
  const handleClick = () => {
    if (visible) {
      return toggleVisible();
    }
    toggleVisible(stream);
  }

  return (
    <div className={`payment-modal-info-item${own ? ' own' : ''}${visible ? ' visible' : ''} ${className || ''}`}>
      <div className='payment-modal-info-item-header' onClick={handleClick}>
        <div className='d-flex flex-grow-1 align-items-center'>
          <div className={`logo-wrap-star payment-modal-info-item-logo`}>
            <div className="logo-bg"><SVG src={star_bg}/></div>
            <img src={head} alt="logo"/>
          </div>
          <div className='d-grid flex-column justify-content-between flex-grow-1 overflow-hidden pe-3'>
            <div className="bold text-14 text-truncate">{stream.stream_name}</div>
            <DateInfo date={stream.start_date_utc} className='d-flex' start/>
          </div>
        </div>
        <div className='payment-modal-info-item-price bold text-nowrap'>
          {price} {currency}
        </div>
        <div className='payment-modal-info-item-arrow'>
          <RenderSvg path={chevron}/>
        </div>
      </div>
      {visible
        ?
        <div className='px-2 pb-3'>
          {stream?.description
            ? <p className="payment-modal-desc muted px-3">{stream.description}</p>
            : null
          }
          {!own
            ?
            <div className='px-2'>
              <a href={`https://${stream.subdomain}.${window.location.hostname}`} target='_blank'
                 className='btn btn-primary'>
                <div>{translate('SELECT_TARIFF')}</div>
              </a>
            </div>
            : null
          }
        </div>
        :
        null
      }
    </div>
  );
}


export default observer(HyperpayStreamInfoListItem);