import React, {FC, useCallback, useState} from 'react';
import './courses.css'
import {ICoursesComponentProps} from '../../interfaces/i_courses';
import PageTitle from '../../components/PageTitle';
import course_books from '../../assets/icons/course_books.svg';
import Tabs from '../../components/tabs/Tabs';
import CoursesList from './CoursesList';

interface Props extends ICoursesComponentProps {

}

const Courses: FC<Props> = (props) => {
  const [activeTab, setActiveTab] = useState(0);
  const handleChangeTab = useCallback((i: number) => {
    setActiveTab(i);
    props.changeFilter('passed', i);
  }, []);

  return (
    <main>
      <div className='courses'>
      <PageTitle title="My_courses" icon={course_books} />
      <Tabs tabs={['CURRENT_COURSES', 'COMPLETED_COURSES']} active={activeTab} onChange={handleChangeTab} />
      <CoursesList {...props} />
      </div>
    </main>
  );
}

export default Courses;
