import React, {FC} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Login from '../containers/auth/Login';
import Recovery from '../containers/auth/Recovery';
import RecoveryPassword from '../containers/auth/RecoveryPassword';

interface Props {

}

const AuthNavigation: FC<Props> = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/recovery" render={() => <Recovery />} />
        <Route exact path="/recovery-password" render={() => <RecoveryPassword title={'RECOVERY_PASSWORD'} description={'AUTH_RECOVERY_PASSWORD_DESCRIPTION'} />} />
        <Route exact path="/invitation" render={() => <RecoveryPassword title={'CREATE_PASSWORD'} description={'CREATE_PASSWORD_DESCRIPTION'} />} />
        <Route exact path="/invitation" render={() => <RecoveryPassword title={'CREATE_PASSWORD'} description={'CREATE_PASSWORD_DESCRIPTION'} />} />
        <Route exact path="/privacy" render={() => <Login />} />
        <Route exact render={() => <Login />} />
      </Switch>
    </Router>
  );
}


export default AuthNavigation;
