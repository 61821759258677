import React, {FC} from 'react';
import HyperpayInfoForm from "./HyperpayInfoForm";
import {Helmet} from "react-helmet";
import {endpoint} from "../../../utils";
import {ICourse, ICoursePaymentInfo} from "../../../interfaces/i_courses";
import {IOrder} from "../../../interfaces/i_app";


interface Props {
  order?: IOrder;
  stream: ICourse;
  setOrder: (order: IOrder) => void;
  paymentInfo: ICoursePaymentInfo|null;
}

const HyperpayCard: FC<Props> = ({order, stream, setOrder, paymentInfo}) => {
  return (
    <div className='payment-modal-card'>
      {!order
        ?
        <HyperpayInfoForm stream={stream} setOrder={setOrder} paymentInfo={paymentInfo} />
        :
        <>
          <Helmet>
            <script async src={`https://eu-prod.oppwa.com/v1/paymentWidgets.js?checkoutId=${order.order_id}`}/>
          </Helmet>
          <form action={`${endpoint}/payment/hyperpay/callback`} className="paymentWidgets"
                data-brands="VISA MASTER AMEX">
          </form>
        </>
      }
    </div>
  );
}

export default HyperpayCard;