import React, {useEffect} from 'react';
import Navigation from './navigation';
import {observer} from 'mobx-react';
import RootStore from './store/RootStore';
import queryString from 'query-string';
import {DOC_TITLE, FAVICON} from "./utils/api";
import {toast, translate} from "./utils";

function App() {
  useEffect(() => {
    document.title = DOC_TITLE;
    const favicon: any = document.getElementById("favicon"); // Accessing favicon element
    favicon.href = FAVICON;
    init();
  }, []);

  const init = async () => {
    try {
      let params: any = queryString.parse(window.location.search)
      if (params.token) {
        localStorage.setItem('token', params.token);
        //@ts-ignore
        const url = new URL(document.location);
        const searchParams = url.searchParams;
        searchParams.delete("token"); // удалить параметр "test"
        window.history.pushState({}, '', url.toString());
      }
      await RootStore.appStore.init();

    } catch (e) {
    }
  }

  if (!RootStore.appStore.ready) return null;
  return (
    <Navigation/>
  );
}


export default observer(App);
