import React from 'react';
import './close_btn.css';
import close from '../../assets/icons/close.svg';
import SVG from 'react-inlinesvg';


const CloseBtn = ({onClick}) => {
  return (
    <button type='button' className="close-btn" onClick={onClick}>
      <SVG src={close} />
    </button>
  );
}

export default CloseBtn;
