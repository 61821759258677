import React, {FC} from 'react';
import {ICourse} from '../../interfaces/i_courses';
import DateInfo from '../../components/date_info/DateInfo';
import ChatLink from '../../components/chat_link/ChatLink';
import BigStar from '../../components/BigStar';
import Timer from '../../components/timer/Timer';
import {translate} from '../../utils';
import {observer} from 'mobx-react';

interface Props {
  className?: string;
  data: ICourse;
  handleMaterialClick?: () => void;
  getMaterialsList: () => void;
}

const ContentCourseAbout: FC<Props> = ({className, data, handleMaterialClick, getMaterialsList}) => {
  if (!data?.id) return null;
  return (
    <div className="course-content-left">
      <div className={`course-about ${className || ''}`}>
        {data ? <BigStar index={data?.id || 0} animated className="course-about-star" /> : null}
        <div className="pb-4 flex-md-grow-1">
          <div className="course-about-stream-name">{data.stream_name}</div>
          <h2 className="title-48 pt-2 pb-3">{data.name}</h2>
          <p className="muted">{data.description}</p>
          <DateInfo date={data.start_date_utc} start />
          <Timer title="BEFORE_START" startDate={data?.start_date_utc} afterTimerEnded={getMaterialsList} />
        </div>
        <div style={{padding: 1}}>
          <ChatLink text link={data?.chat_link} />
          {handleMaterialClick
            ?
            <button className="btn btn-primary see-material-btn" type="button" onClick={handleMaterialClick}>{translate('See_material')}</button>
            :
            null
          }
        </div>
      </div>
    </div>
  );
}

export default observer(ContentCourseAbout);
