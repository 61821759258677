import React, {FC} from 'react';
import {ICourseMaterial} from '../../interfaces/i_courses';
import {SiderDate} from './sider_materials';
import RenderSvg from '../../components/RenderSvg';
import arrow from '../../assets/icons/arrow-right-dark.svg'

interface Props {
  number?: number;
  material?: ICourseMaterial;
  onClick: (material: any) => void;
  updateData?: () => void;
}

const NextMaterial: FC<Props> = ({material, number, onClick, updateData}) => {
  if (!material || (!material.available && !material.open)) return null;
  const currNumber = (Number(number) + 1);
  return (
    <div className="next-material">
      {material.available
        ?
        <div className="next-material-item" onClick={() => onClick({...material, number: currNumber})}>
          <span className="next-material-item-numeric">{currNumber || 'N/A'}</span>
          <span className="text-truncate" dir={'auto'}>{material?.name}</span>
          <div className="next-material-item-arrow"><RenderSvg path={arrow} rotate={90} size={20} /></div>
        </div>
        :
        <SiderDate date={material.open} updateSider={updateData}/>
      }
    </div>
  );
}

export default NextMaterial;
