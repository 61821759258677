import React, {FC, useEffect, useState} from 'react';
import {ICourse, ICoursePaymentInfo} from '../../interfaces/i_courses';
import BigStar from '../../components/BigStar';
import {GoToBtn, PrimaryBtn} from '../../components/buttons';
import DateInfo from '../../components/date_info/DateInfo';
import {observer} from 'mobx-react';
import {translate} from '../../utils';
import RenderSvg from '../../components/RenderSvg';
import check from '../../assets/icons/check.svg';
import {apiGetCoursePaymentInfo} from "../../services/apiCourses";
import HyperpayModal from "./HyperpayModal";

interface Props {
  course: ICourse;
  index: number;
  onClick: (course: ICourse) => void;
  handlePassedCourse: (id: number) => void;
}

const CoursesListItem: FC<Props> = ({course, onClick, handlePassedCourse}) => {
  const [ready, setReady] = useState(false);
  const [streamPaymentInfo, setStreamPaymentInfo] = useState<ICoursePaymentInfo|null>(null);
  const [hyperPayModalVisible, setHyperPayModalVisible] = useState(false);

  useEffect(() => {
    if (!course.payment_status) {
      init();
    } else {
      setReady(true);
    }
  }, []);

  const init = async () => {
    try {
      const res = await apiGetCoursePaymentInfo(course.id!);
      setStreamPaymentInfo(res);
    } catch (e) {

    } finally {
      setReady(true);
    }
  }

  return (
    <div className="col-12 col-lg-6 course-item-col">
      <div className="courses-item">
        <BigStar index={course.id || 0}/>
        <div className="courses-item-content">
          <div className="stream-name" dir="auto">{course.stream_name}</div>
          <div className="text-truncate title-32 mb-1" dir="auto">{course.name}</div>
          <div className='course-desc text-truncate-2' dir={'auto'}>
            {course.description}
          </div>
          <DateInfo date={course.start_date_utc} start className="courses-item-date"/>
          {ready
            ?
            streamPaymentInfo
              ?
              <PrimaryBtn onClick={() => setHyperPayModalVisible(true)} className='courses-item-btn-payment'>
                <span dir="auto" className='text-nowrap'>{translate('PAY_FOR_EDUCATION')}</span>
                <div className={'ps-2 bold text-nowrap'}>{streamPaymentInfo?.price} {streamPaymentInfo?.currency}</div>
              </PrimaryBtn>
              :
              <div className="position-relative d-flex align-items-center mt-3 flex-wrap">
                <GoToBtn onClick={() => onClick(course)}/>
                {course?.open_all && !course.status
                  ?
                  <div className="mark-passed" onClick={() => handlePassedCourse(course.id || 0)}>
                    <span>{translate('MARK_AS_PASSED')}</span>
                    <span className="mark-passed-icon"><RenderSvg path={check} size={20}/></span>
                  </div>
                  :
                  null
                }
              </div>
            :
            null
          }
        </div>
      </div>
      <HyperpayModal
        stream={course}
        paymentInfo={streamPaymentInfo}
        visible={hyperPayModalVisible}
        onClose={() => setHyperPayModalVisible(false)}
      />
    </div>
  );
}


export default observer(CoursesListItem);
