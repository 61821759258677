import React, {FC, useEffect} from 'react';
import LoginForm from './AuthForm';
import RootStore from '../../store/RootStore';
import LoginIcon from './LoginIcon';
import {useHistory} from 'react-router-dom';
import {TermsLinks} from "../terms";


interface Props {

}

const Login: FC<Props> = () => {
  const history = useHistory();

  // useEffect(() => {
  //   if (history.location.pathname === "/privacy") {
  //     history.replace('/', {privacy: true});
  //   }
  // },[history]);

  const onSubmit = async (values: any) => {
    try {
      history.replace('/');
      await RootStore.userStore.logIn(values);
    } catch (e) {
      throw e;
    }
  };

  return (
    <>
      <LoginIcon screen={'login'} />
      <LoginForm
        errorTitle="AUTH_ERROR"
        title={'Login'}
        btnTitle={'Login'}
        onSubmit={onSubmit}
        fields={['email', 'password']}
      />
      <TermsLinks />
    </>
  );
}

export default Login;
