import React, {FC} from 'react';
import {ITaskAnswer} from '../../../interfaces/i_tasks';
import Avatar from '../../avatar';
import File from '../../file/File';
import RenderSvg from '../../RenderSvg';
import chat_curator from '../../../assets/icons/chat_curator.svg';
import {getLocalDate, translate} from '../../../utils';
import {observer} from 'mobx-react';
import rootStore from '../../../store/RootStore';

interface Props {
  answer: ITaskAnswer;
}

const TaskAnswerItem: FC<Props> = ({answer}) => {
  return (
    <div className="answer-item">
      <div className="d-flex align-items-center mb-3">
        {answer?.user_id ? <RenderSvg path={chat_curator} size={32}/> : <Avatar user={rootStore.userStore.user} small />}
        <div className="answer-item-user">
          <div className="bold-14 text-truncate" dir={'auto'}>{answer.user?.name || rootStore.userStore.user?.name}</div>
          {answer?.user_id ?<div className="medium-12 muted">{translate('Curator')}</div> : null }
        </div>
        <div className='text-right ps-2'>
          <div className='muted-14'>{getLocalDate(answer.created_at || '', 'DD.MM.YY')}</div>
          <div className='muted-12'>{getLocalDate(answer.created_at || '', 'HH:mm')}</div>
        </div>
      </div>
      <p className="text-14" dir='auto'>{answer.message}</p>
      {answer.files?.map(file => (
        <File key={file.uuid} file={file} />
      ))}
    </div>
  );
}


export default observer(TaskAnswerItem);
