import React, {FC, useRef, useState} from 'react';
import {addHostToPath} from '../../utils';
import './video_player.css';
import ReactPlayer from 'react-player'
import {IUpload} from '../../interfaces/i_upload';

interface Props {
  file: IUpload;
}

const VideoPlayer: FC<Props> = ({file}) => {
  const _video: any = useRef(null);
  const [paused, setPaused] = useState(true);


  return (
    <div className="video-player" >
      <ReactPlayer
        playing={!paused}
        height={'100%'}
        width={'100%'}
        controls={true}
        ref={_video}
        onPause={() => setPaused(true)}
        onPlay={() => setPaused(false)}
        url={addHostToPath(file)}
      />
    </div>
  );
}

export default VideoPlayer;
