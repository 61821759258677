import React, {FC} from 'react';
import './form_input.css';
import {translate} from '../../utils';
import RenderSvg from '../../components/RenderSvg';
import lock from '../../assets/icons/lock.svg';
import mail from '../../assets/icons/mail.svg';
import user from '../../assets/icons/user.svg';
import {observer} from 'mobx-react';

const iconsMap: any = {
  email: mail,
  password: lock,
  name: user,
}

interface Props {
  visible?: boolean;
  value?: string;
  label?: string;
  name: string;
  onChange: (name: string, e: string) => void;
}

const FormInput: FC<Props> = ({visible, name, value, label, onChange}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(name, e.target.value);
  }
  if (!visible) return null;
  return (
    <div className={`form-input-wrap${value ? ' filled' : ''}`}>
      <input
        type={name}
        className="input"
        required
        value={value}
        name={name}
        onChange={handleChange}
      />
      <div className="form-input-placeholder">{translate(label || name)}</div>
      <div className="input-icon"><RenderSvg path={iconsMap[name]} size={20} /></div>
    </div>
  );
}

export default observer(FormInput);
